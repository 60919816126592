import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { Category } from "../models/category";
import { Album } from "../models/album";
import { Track } from "../models/track";

export interface PaginationInfo {
    total: number
    page: number
    limited: number
    skipped: number
}

export class MediaAPI {

    public static getCategories(): Promise<Category[]> {
        let apiRequest = new AuthenticatedRequest<Category>('/categories', HTTPMethod.GET);

        return apiRequest.send(Category).then((response) => {
            return response.data;
        });
    }

    public static getAlbums(searchOptions: QuerySearchParameters, skip: number = 0, limit: number = 40): Promise<{ albums: Album[], paging: PaginationInfo }> {
        let apiRequest = new AuthenticatedRequest<Album>('/albums', HTTPMethod.GET, APIVersion.v1_0, { ...searchOptions, skip, limit });

        return apiRequest.send(Album).then((response) => {
            return { albums: response.data, paging: response.pagination! };
        });
    }

    public static getLikedAlbums(searchOptions: QuerySearchParameters, skip: number = 0, limit: number = 40): Promise<{ albums: Album[], paging: PaginationInfo }> {
        let apiRequest = new AuthenticatedRequest<Album>('/albums/liked', HTTPMethod.GET, APIVersion.v1_0, { ...searchOptions, skip, limit });

        return apiRequest.send(Album).then((response) => {
            return { albums: response.data, paging: response.pagination! };
        });
    }

    public static getMostLikedAlbums(searchOptions: QuerySearchParameters, skip: number = 0, limit: number = 40): Promise<{ albums: Album[], paging: PaginationInfo }> {
        let apiRequest = new AuthenticatedRequest<Album>('/albums/most-liked', HTTPMethod.GET, APIVersion.v1_0, { ...searchOptions, skip, limit });

        return apiRequest.send(Album).then((response) => {
            return { albums: response.data, paging: response.pagination! };
        });
    }

    public static getMostDownloadedAlbums(searchOptions: QuerySearchParameters, skip: number = 0, limit: number = 40): Promise<{ albums: Album[], paging: PaginationInfo }> {
        let apiRequest = new AuthenticatedRequest<Album>('/albums/most-downloaded', HTTPMethod.GET, APIVersion.v1_0, { ...searchOptions, skip, limit });

        return apiRequest.send(Album).then((response) => {
            return { albums: response.data, paging: response.pagination! };
        });
    }

    public static like(album: Album): Promise<Album> {
        let apiRequest = new AuthenticatedRequest<Album>('/album/' + album.id + '/favorite', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send(Album).then((response) => {
            return response.data;
        });
    }

    public static unlike(album: Album): Promise<Album> {
        let apiRequest = new AuthenticatedRequest<Album>('/album/' + album.id + '/favorite', HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send(Album).then((response) => {
            return response.data;
        });
    }

    public static download(track: Track): Promise<{ album: Album, url: string }> {
        let apiRequest = new AuthenticatedRequest<Album>('/track/' + track.id + '/download', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return response.data;
        });
    }

}
export enum SortByQueryOptions {
    DateAsc = 'date_asc',
    DateDesc = 'date_des',
    ArtistAsc = 'artist_asc',
    ArtistDesc = 'artist_desc',
    TitleAsc = 'title_asc',
    TitleDesc = 'title_desc',
    BPMAsc = 'bpm_asc',
    BPMDesc = 'bpm_desc',
    KeyAsc = 'key_asc',
    KeyDesc = 'key_desc'
}
export interface QuerySearchParameters {
    category?: string;
    key?: string;
    date?: string;
    artist?: string;
    exclusive?: boolean
    bpm_max?: string
    bpm_min?: string
    sort_by?: SortByQueryOptions
    keywords?: string;
}
