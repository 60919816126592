import * as queryString from 'query-string'

export function request(options: Options, cb: RequestCallback) {
    let path = options.url;
    if (options.qs) {
        const stringified = queryString.stringify(options.qs);
        if (stringified)
            path += "?" + stringified;
    }
    let body = options.body;
    if (options.json) {
        body = JSON.stringify(body);
        options.headers['Content-Type'] = 'application/json'
    }

    let opts = {
        method: options.method,
        body: body,
        headers: Object.assign({}, options.headers, { "User-Agent": options.user_agent }),
        credentials: ("include" as RequestCredentials)
    };
    fetch(path, opts).then(response => {
        response.json().then(json => {
            cb(undefined, response, json);
        }).catch(err => {
            cb(undefined, response, {});
        })
    }).catch(err => {
        cb(err, undefined, undefined);
    })
}

export interface RequestCallback {
    (error?: Error, response?: Response, body?: any): void
}

export interface Options {
    method: 'POST' | 'GET' | 'PUT' | 'DELETE'
    qs?: any;
    headers?: any;
    url: string;
    json: boolean;
    body?: any;
    form?: any;
    auth?: any;
    user_agent: string;
}
