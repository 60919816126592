import * as React from 'react'
import ClubsetsHeader from '../Components/ClubsetsHeader';
import { Colors, Assets } from '../assets';
import { translations } from '../translations';
import { Box } from '../Components/Box';
import { ClubsetsButton } from '../Components/ClubsetsButton';
import { Collapse } from 'reactstrap';
import { UserContextConsumer } from '../context/user';
import { AudioVideoProps } from '../api/models/media';
import { InjectedCheckoutForm } from './Checkout';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UserAPI } from '../api/endpoints/user';
import { ClubsetsLoader } from '../Components/ClubsetsLoader';
import { FAQ } from '../Components/FAQ';

export interface PremiumProps extends AudioVideoProps, RouteComponentProps { }

export const Premium = withRouter(class Premium extends React.Component<PremiumProps, {
    selectedStep: 1 | 2
    loading: boolean
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            selectedStep: 1,
            loading: true
        }
    }

    componentDidMount() {
        UserAPI.getSubscription().then(() => {
            this.props.history.push('/billing');
        }).catch(() => {
            this.setState({ loading: false })
        })
    }

    renderStep1() {
        if (this.state.loading) {
            return <ClubsetsLoader />
        }
        return <>
            <div className="headline">
                <span>{translations.StartClubsets}</span>
            </div>
            <div className="subhead">
                <span>{translations.MembershipInfo}</span>
            </div>
            <Box
                bgColor={Colors.BLACK}
                header={translations.GoPremium}
                className="premium-box"
            >
                <div className="premium-inner">
                    <div className="price-container">
                        <span className="price">{'$9.99'}</span>
                        <span style={{ margin: "0px 0px 12px 4px" }}>{'/mo'}</span>
                    </div>
                    <div>
                        <span className="info">{translations.PremiumInfo}</span>
                        <p><b>{translations.TrialDescription}</b></p>
                    </div>
                    <ClubsetsButton
                        text={translations.GetStarted}
                        onPress={() => {
                            this.setState({ selectedStep: 2 })
                        }}
                    />
                </div>
            </Box>
        </>
    }

    renderStep2() {
        if (this.state.loading) {
            return <ClubsetsLoader />
        }
        return <Box
            bgColor={Colors.BLACK}
            header={translations.CreditCardInfo}
            className="premium-box">
            <div>
                <InjectedCheckoutForm onSubscribed={() => {
                    this.props.history.push('/billing');
                }} />
            </div>
        </Box>
    }

    render() {
        return (
            <UserContextConsumer>{(user) =>
                <div className="premium-container">
                    <ClubsetsHeader
                        type={'logo'}
                        profileImageUrl={user && user.profile_image_thumbnail_url}
                    />
                    <div className="membership-container">
                        <div className="select-container">
                            <div className="select-item" onClick={() => {
                                this.setState({ selectedStep: 1 })
                            }}>
                                <div className="step">
                                    <span>{'1'}</span>
                                </div>
                                <span className={this.state.selectedStep === 1 ? 'selected' : 'not-selected'}>{translations.SelectPremium}</span>
                            </div>
                            <div className="select-item" onClick={() => {
                                this.setState({ selectedStep: 2 })
                            }}>
                                <div className="step">
                                    <span>{'2'}</span>
                                </div>
                                <span className={this.state.selectedStep === 2 ? 'selected' : 'not-selected'}>{translations.ReviewAndPay}</span>
                            </div>
                        </div>
                        {this.state.selectedStep == 1 && this.renderStep1()}
                        {this.state.selectedStep == 2 && this.renderStep2()}
                    </div>
                    <FAQ />
                </div>
            }</UserContextConsumer>

        )
    }
})