import { BaseModel } from "./base";
import { Track } from "./track";
import { Category } from "./category";
import { Genre } from "./genre";

export class Album extends BaseModel {
    created_at: string
    updated_at: string;

    name: string;
    artist: string

    bpm: number
    key: string

    artist_info: string[]

    artwork_url: string

    dirty_version_id: string
    clean_version_id: string

    is_favorited: boolean
    exclusive: boolean

    Tracks: Track[]
    Category: Category
    Genre?: Genre
}