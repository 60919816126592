import * as React from 'react'

export interface ClubsetsTextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    className?: string
    caption?: string
    placeholder?: string
    type?: string
    onChange: (text: string) => void;
    onEnter?: () => void;
    onFocus?: () => void;
}

export class ClubsetsTextInput extends React.Component<ClubsetsTextInputProps, {
    value: string
}> {

    constructor(props: ClubsetsTextInputProps) {
        super(props);
        this.state = {
            value: ''
        }

        this.handleChange = this.handleChange.bind(this)
    }

    render() {
        const { caption, className, onFocus, onChange, placeholder, type, ...rest } = this.props
        return (
            <div className={"input-container " + (className || '')}>
                {caption != undefined && <span>{caption}</span>}
                <input
                    type={type || 'text'}
                    className="input"
                    value={this.state.value}
                    placeholder={placeholder}
                    onChange={this.handleChange}
                    onFocus={onFocus}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            this.props.onEnter && this.props.onEnter()
                        }
                    }} {...rest}
                />
            </div>
        )
    }

    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ value: event.target.value })
        this.props.onChange(event.target.value)
    }
}