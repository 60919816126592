import React from 'react'
import { Assets } from '../../src/assets/index'
import { useHistory } from 'react-router-dom'
import { UserAPI } from '../api/endpoints/user'
import { User } from '../api/models/user'
import { Session } from '../api/requests/request'

const Home = () => {
    const history = useHistory()
    const [user, setUser] = React.useState<User>()

    Session.setUnauthorizedHandler(() => {

    })
    React.useEffect(() => {
        UserAPI.getMe().then(user => {
            setUser(user)
        }).catch(() => {
        })
    }, [])

    return (
        <div className="Homepage">
            <header className="header">
                <div className="content-left">
                    <img className="icon no-select" src={Assets.LOGO} alt="BREAKSRUS logo" />
                </div>
                <div className="content-right">
                    {!user && <span className="login-text" onClick={() => {
                        if (user) {
                            history.push('/app')
                        } else
                            history.push('/login')
                    }}>Login</span>}
                    <button className="try-button" onClick={() => {
                        if (user) {
                            history.push('/app')
                        } else
                            history.push('/sign-up')
                    }}>{user != undefined ? 'Record Pool' : 'Try for Free'}</button>
                </div>
            </header>

            <div className="top-content">
                <h1 className="header-text">Helping DJs Throw Down the Best Club Sets</h1>
                <h4 className="subtext">Hand-picked edits and exclusives created for the elite DJ.</h4>
                <div className="cost"><span>$9.99</span> /month</div>
                <button className="browse-button" onClick={() => {
                    if (user) {
                        history.push('/app')
                    } else
                        history.push('/sign-up')
                }}>Browse for FREE</button>
            </div>
            <div className="bottom-content">
                <h2 className="header-text">Bringing the Hottest Sets to Your Party</h2>
                <h4 className="subtext">Get these free edit packs when you sign up.</h4>
                <div className="Album-row">
                    <div className="item">
                        <img className="artwork no-select" src={Assets.HOMEPAGE_ALBUM_1} alt="" />
                        <span className="title">Moombahton Sets</span>
                        <span className="artist">Vol 1</span>
                    </div>
                    <div className="item">
                        <img className="artwork no-select" src={Assets.HOMEPAGE_ALBUM_2} alt="" />
                        <span className="title">Moombahton Sets</span>
                        <span className="artist">Vol 2</span>
                    </div>
                    <div className="item">
                        <img className="artwork no-select" src={Assets.HOMEPAGE_ALBUM_3} alt="" />
                        <span className="title">Reggaeton Sets</span>
                        <span className="artist">Vol 1</span>
                    </div>
                    <div className="item">
                        <img className="artwork no-select" src={Assets.HOMEPAGE_ALBUM_4} alt="" />
                        <span className="title">Reggaeton Sets</span>
                        <span className="artist">Vol 2</span>
                    </div>
                    <div className="item">
                        <img className="artwork no-select" src={Assets.HOMEPAGE_ALBUM_5} alt="" />
                        <span className="title">Trap Sets</span>
                        <span className="artist">Vol 1</span>
                    </div>
                </div>
                <button className="browse-button" onClick={() => {
                    if (user) {
                        history.push('/app')
                    } else
                        history.push('/sign-up')
                }}>Browse for Free</button>
            </div>
            <footer>
                <div className="content-left">
                    <img className="icon" src={Assets.LOGO} alt="BREAKSRUS logo" />
                </div>
                <div className="content-right link" onClick={() => {
                    window.open('terms-and-conditions')
                }}>
                    Terms & Conditions
                </div>
            </footer>
        </div>
    )
}

export default Home