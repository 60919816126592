import * as React from 'react'
import { SettingsNavItem } from '../api/models/settings';
import { translations } from '../translations';

interface SettingsHeaderProps {
    active: SettingsNavItem
    onNavItemClicked: (item: SettingsNavItem) => void;
}


export class SettingsHeader extends React.Component<SettingsHeaderProps, {
}> {

    constructor(props: SettingsHeaderProps) {
        super(props);
        this.state = {
        }
    }

    render() {

        return (
            <div>
                <div className="settings-header">
                    <div className="headline">{'Settings'}</div>
                    <div className={`nav-item ${this.props.active === 'account' ? 'active' : ''}`} onClick={() => this.onNavItemClicked('account')}>{translations.Account}</div>
                    <div className={`nav-item ${this.props.active === 'billing' ? 'active' : ''}`} onClick={() => this.onNavItemClicked('billing')}>{translations.Billing}</div>
                    <div className={`nav-item`} onClick={() => this.onNavItemClicked('home')}>{translations.RecordPool}</div>
                </div>
            </div>

        )
    }

    private onNavItemClicked(item: SettingsNavItem) {
        this.props.onNavItemClicked(item)
    }
}