import { Album } from "./models/album";

const uuidv4 = require('uuid/v4')

export type AlbumChangedListener = (album: Album) => void;

export default class AlbumManager {

    private static instance: AlbumManager;
    private static cache: { [key: string]: Album } = {}

    public static getInstance(): AlbumManager {
        if (AlbumManager.instance) {
            return AlbumManager.instance
        }
        AlbumManager.instance = new AlbumManager();
        return AlbumManager.getInstance()
    }

    private _albumIdStateListeners: { [key: string]: { [key: string]: AlbumChangedListener } } = {};

    public updateAlbum(allbum: Album) {
        AlbumManager.cache[allbum.id] = allbum;
        this.callAlbumStateListeners(allbum)
    }

    public addAlbumStateListener(album: Album, listener: AlbumChangedListener): string {
        let id = uuidv4();
        let listenersObj: { [key: string]: AlbumChangedListener } = {}
        listenersObj[id] = listener;
        this._albumIdStateListeners[album.id] = Object.assign({}, (this._albumIdStateListeners[album.id] || {}), listenersObj)
        if (AlbumManager.cache[album.id]) {
            if (new Date(album.updated_at) < new Date(AlbumManager.cache[album.id].updated_at)) {
                listener(AlbumManager.cache[album.id]);
            }
        }
        return id;
    }

    public removeAlbumStateListener(album: Album, id: string) {
        this._albumIdStateListeners[album.id] && delete this._albumIdStateListeners[album.id][id]
    }

    private callAlbumStateListeners(album: Album) {
        Object.keys(this._albumIdStateListeners[album.id] || {}).map(key => (this._albumIdStateListeners[album.id] || {})[key]).forEach((data) => {
            let cb = data;
            cb(album)
        })
    }
} 