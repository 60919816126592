import React from "react";
import { Assets } from "../assets";

const TermsConditions = () => {
    return (
        <div className="TermsConditions">
            <div className="content-container">
                <div className="tableContents">
                    <img src={Assets.LOGO_DAY} alt="" />
                    <span>
                        <b>Terms and Conditions Table of Contents:</b>
                    </span>
                    <span>Agreement to Terms of Use</span>
                    <span>Content</span>
                    <span>Use of the Site</span>
                    <span>Purchases</span>
                    <span>Accounts, Passwords, and Security</span>
                    <span>Privacy</span>
                    <span>Links to Other Sites</span>
                    <span>Disclaimers</span>
                    <span>Limitation of Liability</span>
                    <span>Indemnity</span>
                    <span>Violation of these Terms of Use</span>
                    <span>Governing Law - Dispute Resolution</span>
                    <span>Void Where Prohibited</span>
                    <span>Miscellaneous</span>
                    <span>Refund Policy</span>
                </div>
                <p>
                    Hi there! Thanks for taking the time to read Club Sets Terms
                    & Conditions (“Terms”). These are important for you, as a
                    user to know because they help outline your legal rights
                    when using Club Sets, they will explain the rights you
                    provide to us when you use our service, and they should
                    clearly explain the rules all users must follow on Club
                    Sets. You ready? Let’s dive in!
                </p>
                <div className="space-top">
                    Please read the following Terms carefully.
                </div>
                <h5 className="space-top">
                    <b>Introduction</b>
                </h5>
                <p>
                    Thank you for your interest in using Club Sets (“Club Sets,”
                    “we,” “us,” “our”). Club Sets provides its users a
                    streamlined service to obtain the latest content from
                    national and international charts.
                </p>
                <h5 className="space-top">
                    1. Ownership of site; agreement to terms of use
                </h5>
                <p>
                    The Terms and Conditions (the "Terms") apply to the Club
                    Sets website currently located at www.breaksrus.com, and all
                    associated sites linked to www.breaksrus.com by Club Sets,
                    its subsidiaries and affiliates, including Club Sets sites
                    around the world (collectively, the "Site"). The Site is the
                    property of Clubs Sets LLC. ("Club Sets") and its licensors.
                </p>
                <p>
                    {" "}
                    BY USING THE SITE, YOU AGREE TO THESE TERMS; IF YOU DO NOT
                    AGREE, DO NOT USE THE SITE
                </p>
                <p>
                    Club Sets reserves the right to change, modify, add/remove
                    portions of these Terms, at any time. It is your
                    responsibility to check these Terms periodically for
                    changes. Your continued use of the Site following the
                    posting of changes will mean that you accept and agree to
                    the changes.
                </p>
                <h5 className="space-top">2. Content</h5>
                <p>
                    All text, graphics, user interfaces, visual interfaces,
                    photographs, trademarks, logos, sounds, music, artwork and
                    computer code (collectively, "Content"), including but not
                    limited to the design, structure, selection, coordination,
                    expression, "look and feel" and arrangement of such Content,
                    contained on the Site is owned, controlled or licensed by or
                    to Clubs Sets and is protected by trade dress, copyright,
                    patent and trademark laws, and various other intellectual
                    property rights and unfair competition laws.
                </p>
                <p>
                    Except as expressly provided in these Terms of Use, no part
                    of the Site and no Content may be copied, reproduced,
                    republished, uploaded, posted, publicly displayed, encoded,
                    translated, transmitted or distributed in any way (including
                    "mirroring") to any other computer, server, Web site or
                    other medium for publication or distribution or for any
                    commercial enterprise, without Club Set’s express prior
                    written consent.
                </p>
                <p>
                    With permission, you may use information on Club Sets
                    products and services (such as articles) purposely made
                    available by Club Sets for downloading from the Site,
                    provided that you (1) not remove any proprietary notice
                    language in all copies of such documents, (2) use such
                    information only for your personal, non-commercial
                    informational purpose and do not copy or post such
                    information on any networked computer or broadcast it in any
                    media, (3) make no modifications to any such information,
                    and (4) not make any additional representations or
                    warranties relating to such documents.
                </p>
                <h5 className="space-top">3. Your use of the site</h5>
                <p>
                    You may not use any "deep-link", "page-scrape", "robot",
                    "spider" or other automatic device, program, algorithm or
                    methodology, or any similar or equivalent manual process, to
                    access, acquire, copy or monitor any portion of the Site or
                    any Content, or in any way reproduce or circumvent the
                    navigational structure or presentation of the Site or any
                    Content, to obtain or attempt to obtain any materials,
                    documents or information through any means not purposely
                    made available through the Site.
                </p>
                <p>
                    You may not attempt to gain unauthorized access to any
                    portion or feature of the Site, or any other systems or
                    networks connected to the Site or to any Club Sets server,
                    or to any of the services offered on or through the Site, by
                    hacking, password "mining" or any other illegitimate means.
                </p>
                <p>
                    You may not probe, scan or test the vulnerability of the
                    Site or any network connected to the Site, nor breach the
                    security or authentication measures on the Site or any
                    network connected to the Site. You may not reverse look-up,
                    trace or seek to trace any information on any other user of
                    or visitor to the Site, or any other customer of Club Sets,
                    including any Club Sets account not owned by you, to its
                    source, or exploit the Site or any service or information
                    made available or offered by or through the Site, in any way
                    where the purpose is to reveal any information, including
                    but not limited to personal identification or information,
                    other than your own information, as provided for by the
                    Site.
                </p>
                <p>
                    You agree that you will not take any action that imposes an
                    unreasonable or disproportionately large load on the
                    infrastructure of the Site or Club Sets systems or networks,
                    or any systems or networks connected to the Site or to Club
                    Sets. You agree not to use any device, software or routine
                    to interfere or attempt to interfere with the proper working
                    of the Site or any transaction being conducted on the Site,
                    or with any other person's use of the Site.
                </p>
                <p>
                    You may not forge headers or otherwise manipulate
                    identifiers in order to disguise the origin of any message
                    or transmittal you send to Club Sets on or through the Site
                    or any service offered on or through the Site. You may not
                    pretend that you are, or that you represent, someone else,
                    or impersonate any other individual or entity.
                </p>
                <p>
                    You may not use the Site or any Content for any purpose that
                    is unlawful or prohibited by these Terms of Use, or to
                    solicit the performance of any illegal activity or other
                    activity, which infringes the rights of Club Sets or others.
                </p>
                <h5 className="space-top">
                    4. Purchases; other terms and conditions
                </h5>
                <p>
                    Additional terms and conditions may apply to purchases of
                    goods or services and to specific portions or features of
                    the Site, including contests, promotions or other similar
                    features, all of which terms are made a part of these Terms
                    for reference. You agree to abide by such other terms and
                    conditions, including where applicable representing that you
                    are of sufficient legal age to use or participate in such
                    service or feature. If there is a conflict between these
                    Terms of Use and the terms posted for or applicable to a
                    specific portion of the Site or for any service offered on
                    or through the Site, the latter terms shall control with
                    respect to your use of that portion of the Site or the
                    specific service.
                </p>
                <p>
                    Club Sets’ obligations, if any, with regard to its products
                    and services are governed solely by the agreements pursuant
                    to which they are provided, and nothing on this Site should
                    be construed to alter such agreements.
                </p>
                <p>
                    Club Sets may make changes to any products or services
                    offered on the Site, or to the applicable prices for any
                    such products or services, at any time, without notice. The
                    materials on the Site with respect to products and services
                    may be out of date, and Club Sets makes no commitment to
                    update the materials on the Site with respect to such
                    products and services.
                </p>
                <p>
                    Each of these policies may be changed from time to time and
                    are effective immediately upon posting such changes on the
                    Site.
                </p>
                <h5 className="space-top">
                    5. Accounts, passwords and security
                </h5>
                <p>
                    Certain features or services offered on or through the Site
                    may require you to open an account (including setting up an
                    Club Sets ID and password). You are entirely responsible for
                    maintaining the confidentiality of your account information,
                    including your password, and for any and all activity that
                    occurs under your account. You agree to notify Club Sets
                    immediately of any unauthorized use of your account or
                    password, or any other breach of security. However, you may
                    be held liable for losses incurred by Club Sets or any other
                    user of or visitor to the Site due to someone else using
                    your Club Sets ID, password or account.You may not use
                    anyone else's Club Sets ID, password or account at any time
                    without the express permission and consent of the holder of
                    that Club Sets ID, password or account. Club Sets cannot and
                    will not be liable for any loss or damage arising from your
                    failure to comply with these obligations.
                </p>
                <h5 className="space-top">6. Privacy</h5>
                <p>
                    Club Sets's Privacy Policy applies to use of this Site, and
                    its terms are made a part of these Terms of Use by this
                    reference. To view Club Sets's Privacy Policy. Additionally,
                    by using the Site, you acknowledge and agree that Internet
                    transmissions are never completely private or secure. You
                    understand that any message or information you send to the
                    Site may be read or intercepted by others, even if there is
                    a special notice that a particular transmission (for
                    example, credit card information) is encrypted.
                </p>
                <h5 className="space-top">
                    7. Links to other sites and to the Club Sets site
                </h5>
                <p>
                    This Site may contain links to other independent third-party
                    Web sites ("Linked Sites"). These Linked Sites are provided
                    solely as a convenience to our visitors. Such Linked Sites
                    are not under Club Sets's control, and Club Sets is not
                    responsible for and does not endorse the content of such
                    Linked Sites, including any information or materials
                    contained on such Linked Sites. You will need to make your
                    own independent judgment regarding your interaction with
                    these Linked Sites.
                </p>
                <h5 className="space-top">8. Disclaimers</h5>
                <p>
                    CLUB SETS DOES NOT PROMISE THAT THE SITE OR ANY CONTENT,
                    SERVICE OR FEATURE OF THE SITE WILL BE ERROR-FREE OR
                    UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED, OR
                    THAT YOUR USE OF THE SITE WILL PROVIDE SPECIFIC RESULTS. THE
                    SITE AND ITS CONTENT ARE DELIVERED ON AN "AS-IS" AND
                    "AS-AVAILABLE" BASIS. ALL INFORMATION PROVIDED ON THE SITE
                    IS SUBJECT TO CHANGE WITHOUT NOTICE. CLUB SETS CANNOT ENSURE
                    THAT ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THE SITE WILL
                    BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES.
                    CLUB SETS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                    INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT,
                    MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. CLUB
                    SETS DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS
                    AND CONDUCT OF ANY THIRD PARTIES IN CONNECTIONWITH OR
                    RELATED TO YOUR USE OF THE SITE AND/OR ANY CLUB SETS
                    SERVICES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF
                    THE SITE AND ANY LINKED SITES. YOUR SOLE REMEDY AGAINST CLUB
                    SETS FOR DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO
                    STOP USING THE SITE OR ANY SUCH CONTENT. THIS LIMITATION OF
                    RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
                </p>
                <p>
                    The above disclaimer applies to any damages, liability or
                    injuries caused by any failure of performance, error,
                    omission, interruption, deletion, defect, delay in operation
                    or transmission, computer virus, communication line failure,
                    theft or destruction of or unauthorized access to,
                    alteration of, or use, whether for breach of contract, tort,
                    negligence or any other cause of action.
                </p>
                <p>
                    Club Sets reserves the right to do any of the following, at
                    any time, without notice: (1) to modify, suspend or
                    terminate operation of or access to the Site, or any portion
                    of the Site, for any reason; (2) to modify or change the
                    Site, or any portion of the Site, and any applicable
                    policies or terms; and (3) to interrupt the operation of the
                    Site, or any portion of the Site, as necessary to perform
                    routine or non-routine maintenance, error correction, or
                    other changes.
                </p>
                <h5 className="space-top">9. Limitation of liability</h5>
                <p>
                    Except where prohibited by law, in no event will Club Sets
                    be liable to you for any indirect, consequential, exemplary,
                    incidental or punitive damages, including lost profits, even
                    if Club Sets has been advised of the possibility of such
                    damages.
                </p>
                <p>
                    If, notwithstanding the other provisions of these Terms of
                    Use, Club Sets is found to be liable to you for any damage
                    or loss which arises out of or is in any way connected with
                    your use of the Site or any Content, Club Sets's liability
                    shall in no event exceed the greater of (1) the total of any
                    subscription or similar fees with respect to any service or
                    feature of or on the Site paid in the six months prior to
                    the date of the initial claim made against Club Sets or (2)
                    US$ 100.00. Some jurisdictions do not allow limitations of
                    liability, so the foregoing limitation may not apply to you.
                </p>
                <h5 className="space-top">10. Indemnity</h5>
                <p>
                    You agree to indemnify and hold Club Sets, its officers,
                    directors, shareholders, predecessors, successors in
                    interest, employees, agents, subsidiaries and affiliates,
                    harmless from any demands, loss, liability, claims or
                    expenses (including attorneys' fees), made against Club Sets
                    by any third party due to or arising out of or in connection
                    with your use of the Site.
                </p>
                <h5 className="space-top">
                    11. Violation of these terms of use
                </h5>
                <p>
                    Club Sets may disclose any information we have about you
                    (including your identity) if we determine that such
                    disclosure is necessary in connection with any investigation
                    or complaint regarding your use of the Site, or to identify,
                    contact or bring legal action against someone who may be
                    causing injury to or interference with (either intentionally
                    or unintentionally) Club Sets's rights or property, or the
                    rights or property of visitors to or users of the Site,
                    including Club Sets's customers Club Sets reserves the right
                    at all times to disclose any information that Club Sets
                    deems necessary to comply with any applicable law,
                    regulation, legal process or governmental request. Club Sets
                    also may disclose your information when Club Sets determines
                    that applicable law requires or permits such disclosure,
                    including exchanging information with other companies and
                    organizations for fraud protection purposes.
                </p>
                <p>
                    You acknowledge and agree that Club Sets may preserve any
                    transmittal or communication by you with Club Sets through
                    the Site or any service offered on or through the Site, and
                    may also disclose such data if required to do so by law or
                    Club Sets determines that such preservation or disclosure is
                    reasonably necessary to (1) comply with legal process, (2)
                    enforce these Terms of Use, (3) respond to claims that any
                    such data violates the rights of others, or (4) protect the
                    rights, property or personal safety of Club Sets, its
                    employees, users of or visitors to the Site, and the public.
                </p>
                <p>
                    You agree that Club Sets may, in its sole discretion and
                    without prior notice, terminate your access to the Site
                    and/or block your future access to the Site if we determine
                    that you have violated these Terms of Use or other
                    agreements or guidelines which may be associated with your
                    use of the Site. You also agree that any violation by you of
                    these Terms of Use will constitute an unlawful and unfair
                    business practice, and will cause irreparable harm to Club
                    Sets, for which monetary damages would be inadequate, and
                    you consent to Club Sets obtaining any injunctive or
                    equitable relief that Club Sets deems necessary or
                    appropriate in such circumstances. These remedies are in
                    addition to any other remedies Club Sets may have at law or
                    in equity.
                </p>
                <p>
                    You agree that Club Sets may, in its sole discretion and
                    without prior notice, terminate your access to the Site, for
                    cause, which includes (but is not limited to) (1) requests
                    by law enforcement or other government agencies, (2) a
                    request by you (self-initiated account deletions), (3)
                    discontinuance or material modification of the Site or any
                    service offered on or through the Site, or (4) unexpected
                    technical issues or problems.
                </p>
                <p>
                    If Club Sets does take any legal action against you as a
                    result of your violation of these Terms of Use, Club Sets
                    will be entitled to recover from you, and you agree to pay,
                    all reasonable attorneys' fees and costs of such action, in
                    addition to any other relief granted to Club Sets. You agree
                    that Club Sets will not be liable to you or to any third
                    party for termination of your access to the Site as a result
                    of any violation of these Terms of Use.
                </p>
                <h5 className="space-top">
                    12. Governing law; dispute resolution
                </h5>
                <p>
                    You agree that all matters relating to your access to or use
                    of the Site, including all disputes, will be governed by the
                    laws of the United States and by the laws of the State of
                    CALIFORNIA without regard to its conflicts of laws
                    provisions. You agree to the personal jurisdiction by and
                    venue in the state and federal courts in San Diego County,
                    California, and waive any objection to such jurisdiction or
                    venue. Any claim under these Terms of Use must be brought
                    within one (1) year after the cause of action arises, or
                    such claim or cause of action is barred. No recovery may be
                    sought or received for damages other than out-of-pocket
                    expenses, except that the prevailing party will be entitled
                    to costs and attorneys' fees. In the event of any
                    controversy or dispute between Club Sets and you arising out
                    of or in connection with your use of the Site, the parties
                    shall attempt, promptly and in good faith, to resolve any
                    such dispute. If we are unable to resolve any such dispute
                    within a reasonable time (not to exceed thirty (30) days),
                    then either party may submit such controversy or dispute to
                    mediation. If the dispute cannot be resolved through
                    mediation, then the parties shall be free to pursue any
                    right or remedy available to them under applicable law.
                </p>
                <h5 className="space-top">13. Void where prohibited</h5>
                <p>
                    Club Sets administers and operates the www.bpmsupreme.com
                    Site from its location in San Diego ,California USA; other
                    Club Sets sites may be administered and operated from
                    various locations inside and outside the United States.
                    Although the Site is accessible worldwide, not all features,
                    products or services discussed, referenced, provided or
                    offered through or on the Site are available to all persons
                    or in all geographic locations, or appropriate or available
                    for use outside the United States. Club Sets reserves the
                    right to limit, in its sole discretion, the provision and
                    quantity of any feature, product or service to any person or
                    geographic area. Any offer for any feature, product or
                    service made on the Site is void where prohibited. If you
                    choose to access the Site from outside the United States,
                    you do so on your own initiative and you are solely
                    responsible for complying with applicable local laws.
                </p>
                <h5 className="space-top">14. Miscellaneous</h5>
                <p>
                    You may not use or export or re-export any Content or any
                    copy or adaptation of such Content, or any product or
                    service offered on the Site, in violation of any applicable
                    laws or regulations, including without limitation United
                    States export laws and regulations.
                </p>
                <p>
                    If any of the provisions of these Terms of Use are held by a
                    court or other tribunal of competent jurisdiction to be void
                    or unenforceable, such provisions shall be limited or
                    eliminated to the minimum extent necessary and replaced with
                    a valid provision that best embodies the intent of these
                    Terms of Use, so that these Terms of Use shall remain in
                    full force and effect. These Terms of Use constitute the
                    entire agreement between you and Club Sets with regard to
                    your use of the Site, and any and all other written or oral
                    agreements or understandings previously existing between you
                    and Club Sets with respect to such use are hereby superseded
                    and cancelled. Club Sets will not accept any counter-offers
                    to these Terms of Use, and all such offers are hereby
                    categorically rejected. Club Sets’ failure to insist on or
                    enforce strict performance of these Terms of Use shall not
                    be construed as a waiver by Club Sets of any provision or
                    any right it has to enforce these Terms of Use, nor shall
                    any course of conduct between Club Sets and you or any other
                    party be deemed to modify any provision of these Terms of
                    Use. These Terms of Use shall not be interpreted or
                    construed to confer any rights or remedies on any third
                    parties.
                </p>
                <p>
                    Club Sets provides access to Club Sets international data
                    and, therefore, may contain references or cross references
                    to Club Sets products, programs and services that are not
                    announced in your country. Such reference does not imply
                    that Club Sets in your country intends to announce such
                    products, programs or services.
                </p>
                <h5 className="space-top">15. Club Sets Refund Policy</h5>
                Read more about our Refund Policy here.
                <h5 className="space-top">16. End User License Grant:</h5>
                <p>
                    Users of Club Sets are working, professional Audio Disc
                    Jockeys and previously Video Disc Jockeys. Subject to the
                    terms and conditions, Club Sets grants to Users of the
                    record pool service, to the extent Club Sets can lawfully
                    grant, and User hereby accepts, a limited, nonexclusive,
                    non-transferable right and license to publicly perform and
                    professionally promote (through loud speakers) Audio and
                    previously Video obtained from the record pool. User has no
                    interest, title, rights, or license to any software,
                    intellectual property, hardware, or documentation used in
                    connection with Audio & previously Video. By using Club Sets
                    you verify that you meet the requirements of a Club Sets
                    User.
                </p>
            </div>
        </div>
    );
};

export default TermsConditions;
