import * as React from 'react'
import { Assets } from '../assets';
import { Box } from '../Components/Box';
import { ClubsetsHeadline } from '../Components/ClubsetsHeadline';
import { translations } from '../translations';
import { ClubsetsTextInput } from '../Components/ClubsetsTextInput';
import { ClubsetsButton } from '../Components/ClubsetsButton';
import isEmail from 'validator/lib/isEmail';
import { RouteComponentProps } from 'react-router';
import { LoginAPI, LoginUserError, LoginUserErrorType } from '../api/endpoints/login';
import { getDevice } from '../api/util';
import { errorToString } from '../api/errors';
import * as toastr from 'toastr'
import { UserAPI } from '../api/endpoints/user';
import { ClubsetsLoader } from '../Components/ClubsetsLoader';
export interface LoginProps extends RouteComponentProps { }

export class Login extends React.Component<LoginProps, {
    email: string
    password: string
    keepMe: boolean

    loadingPW: boolean
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: '',
            password: '',
            keepMe: false,
            loadingPW: false
        }
    }

    private button: ClubsetsButton | null = null

    render() {

        return (
            <div className="flex full-height">
                <div className="login">
                    <div className="login-container">
                        <img src={Assets.LOGO} alt={'logo'} className="logo" onClick={() => {
                            this.props.history.push('/')
                        }} />
                        <Box className="login-box">
                            <ClubsetsHeadline
                                title={translations.SignIn}
                                classname="headline"
                            />
                            <ClubsetsTextInput
                                caption={translations.Email}
                                placeholder={translations.EmailAddress}
                                onEnter={() => { this.login() }}
                                onChange={(email) => { this.setState({ email }) }}
                                onFocus={() => this.button && this.button.hideTooltip()}
                            />
                            <ClubsetsTextInput
                                caption={translations.Password}
                                placeholder={translations.Password}
                                type='password'
                                onEnter={() => { this.login() }}
                                onChange={(password) => { this.setState({ password }) }}
                                onFocus={() => this.button && this.button.hideTooltip()}
                            />
                            <div className="login-button-container">
                                <ClubsetsButton
                                    ref={(ref) => this.button = ref}
                                    text={translations.SignIn}
                                    width={"100%"}
                                    onPress={() => { this.login() }}
                                />
                            </div>
                            <div className="forgotten-password">
                                {this.state.loadingPW && <span><ClubsetsLoader /></span>}
                                {!this.state.loadingPW && <span onClick={() => this.forgotPassword()}>{translations.ForgotenPassword}</span>}
                            </div>
                        </Box>
                        <div className="bottom-box" style={{ marginTop: 16 }}>
                            <span className="text white">{translations.NoAccount}</span>
                            <span className="text orange" onClick={() => this.props.history.push('/sign-up')}>{translations.CreateAccount}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private async forgotPassword() {
        if (isEmail(this.state.email)) {
            this.setState({ loadingPW: true })
            UserAPI.resetPassword(this.state.email).then(() => {
                toastr.success(translations.ForgotPWEmailSent)
                this.setState({ loadingPW: false })
            }).catch(err => {
                toastr.error(translations.EmailNotFound)
                this.setState({ loadingPW: false })
            })
        } else {
            toastr.error(translations.EmailMissing)
        }
    }

    private async login() {
        this.button && this.button.loading(true)
        if (isEmail(this.state.email) && this.state.password.trim() !== '') {
            LoginAPI.login(this.state.email, this.state.password, await getDevice()).then(() => {
                this.props.history.push('/home')
            }).catch((err: LoginUserError) => {
                if (this.button) {
                    this.button.loading(false)
                    if (err.type === LoginUserErrorType.invalidLogin) {
                        this.button.showTooltip(translations.EmailPasswordNotValid)
                    } else {
                        this.button.showTooltip(errorToString(err as Error).join('\n'))
                    }
                }
            })

        } else {
            if (this.button) {
                this.button.loading(false)
                this.button.showTooltip(translations.EmailPasswordNotValid)
            }
        }

    }
}