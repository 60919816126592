import DynamicPlaylist from "./DynamicPlaylist";
import { Album } from "../api/models/album";
import { Track } from "../api/models/track";

export enum AddToHistory {
    NONE,
    LOCAL_ONLY,
    GLOBAL_ONLY,
    ALL,
}

export interface PartialPlaybackMediaInfo {
    album: Album,
    track?: Track,
    queue?: boolean
}
export interface PlaybackMediaInfo {
    album: Album,
    track: Track & { forceMP3?: boolean },
    queue?: boolean
    curated_id?: string
}
export interface FullPlaybackMediaInfo extends PlaybackMediaInfo {
    playlist: DynamicPlaylist,
}
export interface PartialFullPlaybackMediaInfo extends PartialPlaybackMediaInfo {
    playlist: DynamicPlaylist,
}

export const SHUFFLE_KEY = "player_shuffle";
export const AUTOREPLAY_KEY = "player_autoreplay";
export const HQ_KEY = 'hq';
export interface PlaybackState {
    state: PlayerState
    media?: FullPlaybackMediaInfo
    error?: any
}

export interface PlaybackProgressState extends PlaybackState {
    progress?: number;
    duration?: number;
}

export interface PlaybackConfiguration {
    shuffle: boolean;
    autorepeat: boolean;
    hq: boolean;
}

export type PlaybackConfigListener = (config: PlaybackConfiguration) => void;
export type PlaybackStateListener = (state: PlaybackState) => void;
export type PlaybackProgressStateListener = (state: PlaybackProgressState) => void;

export interface CookieProvider {
    isSet(): Promise<boolean>
    set(): Promise<void>
}
export enum PlayerState {
    None = 0,
    Error = 1,
    Paused = 2,
    Loading = 3, //while fetching Track and updating player
    Buffering = 4,
    Playing = 5,
}