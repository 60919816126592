import * as React from 'react'
import { PlayerContextConsumer } from '../context/player';
import { Assets } from '../assets';
import { PlayerState } from './types';
import PlaybackManager from './PlaybackManager';
import moment from 'moment';
import AlbumWrapper from '../context/album';
import { AudioVideoProps } from '../api/models/media';
import HLS from 'hls.js'

export interface ErrorCallback {
    (type: 'FileNotFound' | 'Unknown', err?: any): void
}
export interface PlayCallback {
    (trackDuration: number): void
}
export interface ProgressCallback {
    (progress: number, totalDuration: number): void
}
export interface StateChangeCallback {
    (state: PlayerState): void
}
export interface EndCallback {
    (playButtonTarget?: HTMLElement): void
}
export interface PlayMethod {
    url: string,
    onError: ErrorCallback,
    onPlay: PlayCallback,
    onProgress: ProgressCallback,
    onStateChange: StateChangeCallback,
    onEnd: EndCallback
    totalDuration?: number
}

export interface PlayerProps extends AudioVideoProps {

}

interface PlayerCompState {
    muted: boolean;
    paused: boolean;
    repeat: boolean;
    loading: boolean;
    visible: boolean;
    volume: number;
    currentDuration?: number;
}

export default class Player extends React.Component<PlayerProps, PlayerCompState> {

    private hls_player?: HLS;

    private videoElem?: HTMLVideoElement;
    private onError?: ErrorCallback
    private onPlay?: PlayCallback
    private onStateChange?: StateChangeCallback
    private onProgress?: ProgressCallback
    private onEnd?: EndCallback

    volumeLine: HTMLDivElement | null;
    volumeMouseDown: boolean;

    public get isVisible() {
        return this.state.visible
    }

    static hlsSupported() {
        return HLS.isSupported()
    }

    constructor(props: PlayerProps) {
        super(props);
        this.state = {
            paused: PlaybackManager.getInstance().getPlayingState() == PlayerState.Paused,
            muted: false,
            repeat: PlaybackManager.getInstance().autorepeat,
            loading: false,
            visible: false,
            volume: 0,
        };
        PlaybackManager.getInstance()._internalPlayer = this as any;
        this.bindVideoElem = this.bindVideoElem.bind(this)
        this.handleAudioEvent = this.handleAudioEvent.bind(this);
    }

    private initHLSIfRequired() {
        if (Player.hlsSupported()) {
            if (this.hls_player) {
                this.hls_player.destroy()
                this.hls_player = undefined;
            }
            this.hls_player = new HLS({
                initialLiveManifestSize: 1,
                xhrSetup: function (xhr, url) {
                    xhr.withCredentials = true
                }
            });
            this.hls_player!.on(HLS.Events.ERROR, (event: any, data) => {
                if (data.fatal) {
                    switch (data.type) {
                        case HLS.ErrorTypes.NETWORK_ERROR:
                            if (data.details == 'manifestLoadError') {
                                if (data.networkDetails) {
                                    if (data.networkDetails.status != 200) {
                                        this.onError && this.onError('FileNotFound', data)
                                        return;
                                    }
                                }
                            }
                            this.onError && this.onError('Unknown', data)
                            break;
                        case HLS.ErrorTypes.MEDIA_ERROR:
                            this.hls_player!.recoverMediaError();
                            break;
                        default:
                            break;
                    }
                }
            })
        }
    }

    private isSafari() {
        return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    }

    public play({ url, onEnd, onError, onPlay, onProgress, onStateChange }: PlayMethod) {
        if (!this.state.visible) {
            this.setState({ visible: true })
        }
        this.onError = onError
        this.onPlay = onPlay
        this.onProgress = onProgress
        this.onStateChange = onStateChange
        this.onEnd = onEnd

        this.initHLSIfRequired()

        if (this.hls_player && url.endsWith('m3u8') && !this.isSafari()) {
            this.videoElem && this.hls_player.attachMedia(this.videoElem)
            this.hls_player.on(HLS.Events.MEDIA_ATTACHED, () => {
                this.hls_player!.loadSource(url);
                this.videoElem && this.videoElem.play();
            });
        } else if (this.videoElem) {
            if (this.videoElem.src != url) {
                this.videoElem.src = url;
            }
            this.videoElem.play();
        }

    }

    public pause(cb?: () => void) {
        this.videoElem && this.videoElem.pause()
        this.onStateChange && this.onStateChange(PlayerState.Paused)
        cb && cb()
    }

    public seek(to: number) {
        if (this.videoElem)
            this.videoElem.currentTime = to;
    }

    public stop() {
        this.videoElem && this.videoElem.pause()
    }

    private async handleAudioEvent(event: Event) {
        switch (event.type) {
            case 'play':
                this.onStateChange && this.onStateChange(PlayerState.Buffering)
                // No play event, we do not have the duration yet
                break;
            case 'playing':
                this.onStateChange && this.onStateChange(PlayerState.Playing)
                break;
            case 'pause':
                this.onStateChange && this.onStateChange(PlayerState.Paused)
                break;
            case 'durationchange':
                break;
            case 'timeupdate':

                this.onProgress && this.videoElem && this.onProgress(this.videoElem.currentTime, this.videoElem.duration)
                break;
            case 'loadedmetadata':
                this.onStateChange && this.onStateChange(PlayerState.Playing)
                this.onPlay && this.videoElem && this.onPlay(this.videoElem.duration)
                this.videoElem && this.setState({ currentDuration: this.videoElem.duration })
                break;
            case 'ended':
                this.onEnd && this.onEnd()
                break;
            case 'error':
                this.onError && this.onError('FileNotFound', undefined)
                break;
        }
    }

    private bindVideoElem(elem: HTMLVideoElement) {
        this.videoElem = elem;
        if (!elem) {
            return
        }
        this.hls_player && this.hls_player.attachMedia(this.videoElem);
        this.videoElem.addEventListener('seeking', this.handleAudioEvent);
        this.videoElem.addEventListener('timeupdate', this.handleAudioEvent);
        this.videoElem.addEventListener('seeked', this.handleAudioEvent);
        this.videoElem.addEventListener('pause', this.handleAudioEvent);
        this.videoElem.addEventListener('canplay', this.handleAudioEvent);
        this.videoElem.addEventListener('canplaythrough', this.handleAudioEvent);
        this.videoElem.addEventListener('ended', this.handleAudioEvent);
        this.videoElem.addEventListener('playing', this.handleAudioEvent);
        this.videoElem.addEventListener('error', this.handleAudioEvent);
        this.videoElem.addEventListener('loadedmetadata', this.handleAudioEvent);
        this.videoElem.addEventListener('loadeddata', this.handleAudioEvent);
        this.videoElem.addEventListener('durationchange', this.handleAudioEvent);
        this.videoElem.addEventListener('play', this.handleAudioEvent);
        this.setState({ volume: this.videoElem.volume });
    }

    render() {
        const video = <video ref={this.bindVideoElem} style={{ display: 'block' }} onContextMenu={(e) => {
            e.preventDefault()
            return false;
        }} controlsList="nodownload" width={0} height={0} playsInline={true}
            controls={PlaybackManager.getInstance().isPlayingVideo()} muted={false} onVolumeChange={(e) => {
                if (this.state.volume != e.currentTarget.volume) {
                    this.setState({ volume: e.currentTarget.volume })
                } else if (e.currentTarget.muted != this.state.muted) {
                    this.setState({ muted: e.currentTarget.muted })
                }
            }}></video>
        return (
            <>
                {video}
                <PlayerContextConsumer>{value => {
                    let album = value.album
                    if (!album) {
                        return null
                    }
                    const playing = value.state >= PlayerState.Playing
                    return <AlbumWrapper album={album}>{(album) => {
                        return (
                            <div className={"player-container " + (!this.state.visible ? 'hidden' : '')}>
                                <div className="title-artist-column">
                                    <span className="title">{album ? album.name : ''}</span>
                                    <span className="artist">{album ? album.artist : ''}</span>
                                </div>
                                <div className="play-column">
                                    <div className="button-row">
                                        <div className="icon-container rewind">
                                            <img src={Assets.ICON_REWIND} alt={'Rewind'} className="spool-icon" onClick={() => PlaybackManager.getInstance().rewind()} />
                                        </div>
                                        <div className="icon-container">
                                            <img src={playing ? Assets.PAUSE_BUTTON : Assets.PLAY_BUTTON}
                                                alt={'Play'} className="play-icon" onClick={() => {
                                                    if (PlaybackManager.getInstance().getPlayingState() < PlayerState.Loading) {
                                                        PlaybackManager.getInstance().play()
                                                    } else {
                                                        PlaybackManager.getInstance().pause()
                                                    }
                                                }} />
                                        </div>
                                        <div className="icon-container ff">
                                            <img src={Assets.ICON_FAST_FORWARD} alt={'Fast Forward'} className="spool-icon" onClick={() => PlaybackManager.getInstance().forward()} />
                                        </div>
                                        <div className="icon-container like">
                                            <img src={album.is_favorited ? Assets.ICON_LIKED_SONGS_ORANGE_FILLED : Assets.ICON_LIKED_SONGS}
                                                alt={'liked'} className="like-icon" onClick={() => {
                                                    return this.props.onAlbumLikeToggled(album)
                                                }} />
                                        </div>
                                    </div>
                                    <PlayerProgress />
                                </div>
                                <div className="volume-column">
                                    <div className="volume-container">
                                        <div className="speaker">
                                            <img src={this.state.muted ? Assets.ICON_SPEAKER_MUTED : Assets.ICON_SPEAKER} alt={'Speaker'} className="speaker-icon" onClick={(e) => {
                                                if (this.videoElem) this.videoElem.muted = !this.state.muted
                                                this.setState({ muted: !this.state.muted })
                                            }} />
                                        </div>
                                        <div className="percent-line-container">
                                            <div className='percent-line-drag' onClick={(e) => {
                                                const percent = (100 / (e.currentTarget.clientWidth * .9)) * e.nativeEvent.offsetX
                                                const targetVolume = (percent / 100)
                                                if (this.videoElem) {
                                                    this.setState({ volume: targetVolume, muted: false })
                                                    this.videoElem.volume = targetVolume;
                                                    this.videoElem.muted = false;
                                                }
                                            }} onMouseMove={(e) => {
                                                if (this.volumeMouseDown) {
                                                    const percent = (100 / (e.currentTarget.clientWidth * .9)) * e.nativeEvent.offsetX
                                                    const targetVolume = (percent / 100)
                                                    if (this.videoElem) {
                                                        this.setState({ volume: targetVolume, muted: false })
                                                        this.videoElem.volume = targetVolume;
                                                    }
                                                }
                                            }} onMouseDown={() => {
                                                this.volumeMouseDown = true
                                                const self = this
                                                const listener = function () {
                                                    self.volumeMouseDown = false
                                                    document.removeEventListener('mouseup', listener);
                                                }
                                                document.addEventListener('mouseup', listener)
                                            }}><div>
                                                    <div ref={(ref) => this.volumeLine = ref} className="percent-line back" />
                                                    <div className="percent-line front" style={{ width: `${this.state.muted ? 0 : this.state.volume * 100}%` }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    }}</AlbumWrapper>
                }}
                </PlayerContextConsumer>
            </>
        )
    }
}

class PlayerProgress extends React.Component {
    progressLine: HTMLDivElement | null;
    lineMouseDown: boolean;

    static formatProgress(seconds?: number) {
        if (!seconds) {
            return undefined
        }
        return moment.utc(moment.duration(seconds, "s").asMilliseconds()).format("mm:ss")
    }

    componentDidMount() {
        PlaybackManager.getInstance().addPlaybackProgressStateListener((state) => {
            this.setState({ progressState: state })
        })
    }

    progressInPercent(seconds?: number, duration?: number) {
        if (!seconds || !duration) {
            return 0
        }
        return (100 / duration) * seconds
    }

    render() {
        return <PlayerContextConsumer>{value => <div className="timeline-row">
            <span className="time">{PlayerProgress.formatProgress(PlaybackManager.getInstance().getProgress()) || '00:00'}</span>
            <div className="percent-line-container">
                <div className={[PlayerState.Loading, PlayerState.Buffering].includes(value.state) ? '' : 'percent-line-drag'} onClick={(e) => {
                    if (PlaybackManager.getInstance().getDuration()) {
                        const percent = (100 / (e.currentTarget.clientWidth * .9)) * e.nativeEvent.offsetX
                        const targetDuration = PlaybackManager.getInstance().getDuration()! * (percent / 100)
                        PlaybackManager.getInstance().seek(targetDuration)
                    }
                }} onMouseMove={(e) => {
                    if (this.lineMouseDown) {
                        if (PlaybackManager.getInstance().getDuration()) {
                            const percent = (100 / (e.currentTarget.clientWidth * .9)) * e.nativeEvent.offsetX
                            const targetDuration = PlaybackManager.getInstance().getDuration()! * (percent / 100)
                            PlaybackManager.getInstance().seek(targetDuration)
                        }
                    }
                }} onMouseDown={() => {
                    this.lineMouseDown = true
                    const self = this
                    const listener = function () {
                        self.lineMouseDown = false
                        document.removeEventListener('mouseup', listener);
                    }
                    document.addEventListener('mouseup', listener)
                }}>
                    {![PlayerState.Loading, PlayerState.Buffering].includes(value.state) &&
                        <div ref={(ref) => this.progressLine = ref} className="percent-line back" />}
                    {([PlayerState.Loading, PlayerState.Buffering].includes(value.state)) && <div className='indeterminate' />}
                    {![PlayerState.Loading, PlayerState.Buffering].includes(value.state) &&
                        <div className="percent-line front"
                            style={{ width: `${this.progressInPercent(PlaybackManager.getInstance().getProgress(), PlaybackManager.getInstance().getDuration())}%` }} />}
                </div>
            </div>
            <span className="time right">{PlayerProgress.formatProgress(PlaybackManager.getInstance().getDuration()) || '00:00'}</span>
        </div>}
        </PlayerContextConsumer>
    }
}