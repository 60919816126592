import * as React from "react";
import { Album } from "../api/models/album";
import AlbumManager from "../api/albummanager";

export interface AlbumWrapperProps {
    album: Album,
    children: (album: Album) => JSX.Element
}

export default class AlbumWrapper extends React.Component<AlbumWrapperProps, { album: Album }> {

    constructor(props: AlbumWrapperProps) {
        super(props);
        this.state = {
            album: props.album
        }
    }

    private _albumsStateListener?: string

    componentWillReceiveProps(newProps: AlbumWrapperProps) {
        if (newProps.album && this.state.album && newProps.album.id === this.state.album.id) {
            if (new Date(newProps.album.updated_at) > new Date(this.state.album.updated_at)) {
                this.setState({ album: newProps.album })
            }
        }
        else {
            this.unregisterListener()
            this.setState({ album: newProps.album }, () => { this.registerListener() })
        }
    }

    componentDidMount() {
        this.registerListener();
    }

    componentWillUnmount() {
        this.unregisterListener();
    }

    private registerListener() {
        this._albumsStateListener = AlbumManager.getInstance().addAlbumStateListener(this.state.album, (album: Album) => {
            if (this.state.album.id === album.id) {
                this.setState({ album: album })
            }
        })
    }

    private unregisterListener() {
        this._albumsStateListener && AlbumManager.getInstance().removeAlbumStateListener(this.props.album, this._albumsStateListener)
    }

    render() {
        const { album } = this.state
        return this.props.children(album)
    }
}