import * as React from 'react'
import { Assets } from '../assets';
import { Box } from '../Components/Box';
import { ClubsetsHeadline } from '../Components/ClubsetsHeadline';
import { translations } from '../translations';
import { ClubsetsTextInput } from '../Components/ClubsetsTextInput';
import { ClubsetsButton } from '../Components/ClubsetsButton';
import { RouteComponentProps } from 'react-router';
import { LoginAPI, LoginUserError } from '../api/endpoints/login';
import { errorToString } from '../api/errors';
import * as toastr from 'toastr'
import * as queryString from 'query-string'

export interface LoginProps extends RouteComponentProps, RouteComponentProps { }

export class ForgotPassword extends React.Component<LoginProps, {
    password: string
}> {
    private token: string
    constructor(props: LoginProps) {
        super(props);
        this.state = {
            password: ''
        }
        try {
            this.token = queryString.parse(props.location.search).token as string

        } catch (error) {

        }
        if (!this.token) {
            this.props.history.push('/login')
        }
    }

    private button: ClubsetsButton | null = null

    render() {

        return (
            <div className="flex full-height">
                <div className="login">
                    <div className="login-container">
                        <img src={Assets.LOGO} alt={'logo'} className="logo" />
                        <Box className="login-box">
                            <ClubsetsHeadline
                                title={translations.ResetPassword}
                                classname="headline"
                            />
                            <ClubsetsTextInput
                                caption={translations.Password}
                                placeholder={translations.Password}
                                type='password'
                                onEnter={() => { this.reset() }}
                                onChange={(password) => { this.setState({ password }) }}
                                onFocus={() => this.button && this.button.hideTooltip()}
                            />
                            <div className="login-button-container">
                                <ClubsetsButton
                                    ref={(ref) => this.button = ref}
                                    text={translations.Reset}
                                    width={"100%"}
                                    onPress={() => { this.reset() }}
                                />
                            </div>
                        </Box>
                        <div className="bottom-box" style={{ marginTop: 16 }}>
                            <span className="text orange" onClick={() => this.props.history.push('/login')}>{translations.BackToLogin}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private reset() {
        this.button && this.button.loading(true)
        if (this.state.password.trim() !== '') {
            LoginAPI.resetPassword(this.token, this.state.password).then(() => {
                toastr.success('Your password was reseted!')
                this.props.history.push('/login')
            }).catch((err: LoginUserError) => {
                this.button?.loading(false)
                this.button?.showTooltip(errorToString(err as Error).join('\n'))
            })

        } else {
            if (this.button) {
                this.button.loading(false)
                this.button.showTooltip(translations.PasswordNotValid)
            }
        }

    }
}