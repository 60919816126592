import * as Fingerprint2 from 'fingerprintjs2'
import { DeviceData, DeviceTokenProvider } from './endpoints/login'

export function getDevice(): PromiseLike<DeviceData> {
    return new Promise((resolve) => {
        Fingerprint2.getV18({}, (result: string) => {
            const device = {
                app_version: require('../../package.json').version,
                build_version: '1',
                device_uuid: result,
                device_data_os: 'web',
                app_type: 'admin',
                debug: process.env.REACT_APP_STAGE !== 'production',
                token_provider: DeviceTokenProvider.Web,
                language: navigator.language
            }
            resolve(device)
        })
    })
}

export const CamelotMapping:
    {
        [key: string]: string
    }

    = {
    // Left side standing in for minor keys 
    // Right side standing in for major keys 
    // keys & values may be incorrect
    'g#m': '1A',
    'ebm': '2A',
    'd#m': '2a',
    'bbm': '3A',
    'a#m': '3A',
    'fm': '4A',
    'cm': '5A',
    'gm': '6A',
    'dm': '7A',
    'am': '8A',
    'em': '9A',
    'bm': '10A',
    'f#m': '11A',
    'gbm': '11A',
    'dbm': '12A',
    'c#m': '12A',

    'b': '1B',
    'f#': '2B',
    'gb': '2B',
    'db': '3B',
    'c#': '3B',
    'ab': '4B',
    'g#': '4B',
    'eb': '5B',
    'd#': '5B',
    'bb': '8B',
    'a#': '8B',
    'f': '7B',
    'c': '8B',
    'g': '9B',
    'd': '10B',
    'a': '11B',
    'e': '12B'

}