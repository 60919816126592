import { Device } from "../models/device";
import { APIError, HTTPMethod, BasicRequest, APIVersion, Session } from "../requests/request";
import { APIErrorType } from "../errors";

export enum DeviceTokenProvider {
    Apple = 'apple',
    Google = 'google',
    Web = 'web'
}

export type DeviceData = {
    'app_type': string,
    'app_version': string,
    'build_version': string,
    'device_token'?: string,
    'token_provider': DeviceTokenProvider,
    'device_uuid': string,
    'device_data_os'?: string,
    'device_data_os_version'?: string,
    'device_data_device_type'?: string,
    'device_data_device_name'?: string,
    'device_data_device_category'?: string,
    'debug': boolean,
    'language': string
}
export class LoginUserError extends Error {
    public type: LoginUserErrorType;
    public underlyingError: APIError;

    constructor(type: LoginUserErrorType, underlyingError: APIError) {
        super('User Login Error');
        this.type = type;
        this.underlyingError = underlyingError;
    }
}

export enum LoginUserErrorType {
    invalidLogin,
    userNotFound,
    unknownError
}

export class LoginAPI {

    public static resetPassword(token: string, password: string): Promise<void> {
        let apiRequest = new BasicRequest<void>('/resetpassword', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            token,
            password: password,
        });

        return apiRequest.send().then((response) => {
        })
    }

    /**
     * Login the user
     * @param {string} email
     * @param {string} password
     * @returns {Promise<User>} The Promise contains either a user or a LoginUserError if rejected
     */
    public static login(email: string, password: string, device: DeviceData): Promise<Device> {
        let apiRequest = new BasicRequest<Device>('/login', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            email: email,
            password: password,
            device: device
        });

        return apiRequest.send(Device).then((response) => {
            return response.data
        }).catch((error: APIError) => {
            switch (error.type) {
                case APIErrorType.InvalidParameters:
                    throw new LoginUserError(LoginUserErrorType.invalidLogin, error);
                case APIErrorType.InvalidLogin:
                    throw new LoginUserError(LoginUserErrorType.invalidLogin, error);
                case APIErrorType.ResourceNotFound:
                    throw new LoginUserError(LoginUserErrorType.userNotFound, error);
                default:
                    throw new LoginUserError(LoginUserErrorType.unknownError, error);
            }
        })
    }


    public static register(email: string, full_name: string, password: string, device: DeviceData): Promise<Device> {
        let apiRequest = new BasicRequest<Device>('/users', HTTPMethod.POST, APIVersion.v1_0, undefined, { email, full_name, password, device });

        return apiRequest.send(Device).then((response) => {
            Session.setSession(response.data)
            Session.getUserUpdatedHandler()(response.data.user)
            return response.data;
        });
    }
}
