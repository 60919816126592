import * as React from 'react'
import { AudioVideoProps } from '../api/models/media';
import { PlayerContextConsumer, PlayerContext } from '../context/player';
import moment from 'moment';
import { Assets } from '../assets';
import { Album } from '../api/models/album';
import { PlayerState } from '../player/types';
import { PlaybackManager, DynamicPlaylist, AddToHistory, FullPlaybackMediaInfo } from '../player';
import { Track } from '../api/models/track';
import AlbumWrapper from '../context/album';
import { downloadMessageHandler } from '../util/errormessagehandler';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Category } from '../api/models/category';
import { SortByQueryOptions, PaginationInfo } from '../api/endpoints/media';
import { CamelotMapping } from '../api/util';

interface HideColumns {
    category?: boolean
}
export interface ListViewProps extends AudioVideoProps, RouteComponentProps {
    context: string;
    loading: boolean;
    albums: Album[]
    pagingInfo?: PaginationInfo;
    sorting?: SortByQueryOptions
    chordType: 'minor' | 'major'
    onLoadMore: (page: 'next' | 'prev' | 'first' | 'last' | number) => void
    onCategoryClicked: (c: Category) => void
    onBPMClicked: (bpm: number) => void
    onKeyClicked: (key: string) => void
    onDateClicked: (date: string) => void
    onArtistClick: (artist: string) => void
    onExclusiveClick: () => void
    onSortingChange?: (sortBy?: SortByQueryOptions) => void

    categoryColumnType?: 'genre' | 'category'
    hideColumns?: HideColumns
}

export const ListView = withRouter(class ListView extends React.Component<ListViewProps, {
    cleanLoading?: string
    dirtyLoading?: string
    likeLoading?: string
}> {
    private internallist: DynamicPlaylist;

    static defaultProps = {
        categoryColumnType: 'category' as 'category'
    }

    constructor(props: ListViewProps) {
        super(props);
        this.state = {

        }
        this.internallist = new DynamicPlaylist([], {
            history_mode: AddToHistory.ALL
        }, this.props.context, false)
        this.internallist.replace(this.props.albums.map(album => {
            return {
                album,
                track: album.Tracks[0],
                playlist: this.internallist
            } as FullPlaybackMediaInfo
        }))
    }

    componentDidUpdate(nextProps: ListViewProps) {
        if (nextProps.albums != this.props.albums)
            this.internallist.replace(this.props.albums.map(album => {
                return {
                    album,
                    track: album.Tracks[0],
                    playlist: this.internallist
                } as FullPlaybackMediaInfo
            }))
    }

    changeSortBy = (column: 'date' | 'artist' | 'title' | 'bpm' | 'key') => {
        const { sorting } = this.props
        const { onSortingChange } = this.props
        if (onSortingChange) {
            switch (column) {
                case 'date':
                    if (sorting === SortByQueryOptions.DateDesc) {
                        onSortingChange(SortByQueryOptions.DateAsc)
                    }
                    else if (sorting === SortByQueryOptions.DateAsc) {
                        onSortingChange(undefined)
                    }
                    else onSortingChange(SortByQueryOptions.DateDesc)
                    break

                case 'artist':
                    if (sorting === SortByQueryOptions.ArtistDesc) {
                        onSortingChange(SortByQueryOptions.ArtistAsc)
                    }
                    else if (sorting === SortByQueryOptions.ArtistAsc) {
                        onSortingChange(undefined)
                    }
                    else onSortingChange(SortByQueryOptions.ArtistDesc)
                    break

                case 'title':
                    if (sorting === SortByQueryOptions.TitleDesc) {
                        onSortingChange(SortByQueryOptions.TitleAsc)
                    }
                    else if (sorting === SortByQueryOptions.TitleAsc) {
                        onSortingChange(undefined)
                    }
                    else onSortingChange(SortByQueryOptions.TitleDesc)
                    break

                case 'bpm':
                    if (sorting === SortByQueryOptions.BPMDesc) {
                        onSortingChange(SortByQueryOptions.BPMAsc)
                    }
                    else if (sorting === SortByQueryOptions.BPMAsc) {
                        onSortingChange(undefined)
                    }
                    else onSortingChange(SortByQueryOptions.BPMDesc)
                    break
                case 'key':
                    if (sorting === SortByQueryOptions.KeyDesc) {
                        onSortingChange(SortByQueryOptions.KeyAsc)
                    }
                    else if (sorting === SortByQueryOptions.KeyAsc) {
                        onSortingChange(undefined)
                    }
                    else onSortingChange(SortByQueryOptions.KeyDesc)
                    break
            }
        }
    }

    public play(shuffle: boolean = false) {
        PlaybackManager.getInstance().getContentManager().replaceLists(this.internallist)
        PlaybackManager.getInstance().shuffle = shuffle;
        PlaybackManager.getInstance().startTrack(this.internallist.tracks()[0])
    }

    private onMediaSelected(media: Track, album: Album) {
        PlaybackManager.getInstance().getContentManager().replaceLists(this.internallist)
        PlaybackManager.getInstance().startTrack({
            album,
            track: media,
            playlist: this.internallist
        })
        this.props.onMediaSelected && this.props.onMediaSelected(media, album)
    }

    public onAlbumSelected(album: Album) {
        PlaybackManager.getInstance().getContentManager().replaceLists(this.internallist)
        PlaybackManager.getInstance().startTrack(this.internallist.tracks().find(a => a.album.id == album.id)!)
        this.props.onAlbumSelected && this.props.onAlbumSelected(album)
    }

    public onPause() {
        PlaybackManager.getInstance().pause()
    }

    public isAlbumSelected(album: Album, playerContext: PlayerContext) {
        if (playerContext && playerContext.state >= PlayerState.Loading) {
            return album.Tracks.find(m => m.id == playerContext!.track!.id) != undefined
        }
        return false;
    }

    render() {
        const { albums } = this.props
        return (
            <PlayerContextConsumer>{(playerContext) =>
                <div className="listview">
                    {this.renderHeader()}
                    <div className="listview-container">
                        {albums.map((item) => {
                            const albumPlaying = this.isAlbumSelected(item, playerContext)
                            return (
                                <ListViewItem
                                    album={item} key={item.id} hideColumns={this.props.hideColumns}
                                    playerContext={playerContext} categoryColumnType={this.props.categoryColumnType!}
                                    {...this.props}
                                    onPlayPauseClicked={(e, item) => {
                                        e.stopPropagation()
                                        if (playerContext.state >= PlayerState.Loading && albumPlaying) {
                                            this.onPause()
                                        } else {
                                            this.onAlbumSelected(item)
                                        }
                                    }}
                                    onDownloadClicked={(e, track) => {
                                        let config = {
                                            target: e.target! as HTMLElement,
                                            desiredPosition: 'up'
                                        }
                                        return this.props.onTrackDownload ? this.props.onTrackDownload(item, track).catch(err => {
                                            downloadMessageHandler(err, config, this.props.history)
                                        }) : Promise.resolve()
                                    }}
                                    onLikeClicked={(e, item) => {
                                        let config = {
                                            target: e.target! as HTMLElement,
                                            desiredPosition: 'up'
                                        }
                                        return this.props.onAlbumLikeToggled ? this.props.onAlbumLikeToggled(item).catch(err => {
                                            downloadMessageHandler(err, config, this.props.history)
                                        }) : Promise.resolve()
                                    }}
                                />
                            )
                        })}
                        <div className='pagination-container'>
                            {this.renderPager()}
                        </div>
                    </div>
                </div>
            }</PlayerContextConsumer>
        )
    }

    private calculatePagination(current: number, last: number, moreSymbol: Symbol) {
        var delta = 1,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || i >= left && i < right) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push(moreSymbol);
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    private renderPager() {
        if (this.props.pagingInfo) {
            const moreSymbol = Symbol('More')
            const endNumber = Math.ceil(this.props.pagingInfo.total / this.props.pagingInfo.limited)
            const current = this.props.pagingInfo.page;
            const paginationArray = endNumber != 1 ? this.calculatePagination(current, endNumber, moreSymbol) : []
            return (
                <ul className='pagination'>
                    {current > 1 && <>
                        <li>
                            <a href="javascript:void(0)" onClick={() => this.props.onLoadMore('first')}>
                                <span aria-hidden="true">«</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" onClick={() => this.props.onLoadMore('prev')}>
                                <span aria-hidden="true">‹</span>
                            </a>
                        </li>
                    </>}
                    {paginationArray.map((item, index) => {
                        return <li key={index} className={(item != moreSymbol && item == current) ? 'selected' : ((item == moreSymbol) ? 'more' : '')}>
                            <a href={item == moreSymbol ? undefined : "javascript:void(0)"} onClick={item == moreSymbol ? undefined : (() => this.props.onLoadMore(item as number))}>
                                <span aria-hidden="true">{item == moreSymbol ? '…' : item}</span>
                            </a>
                        </li>
                    })}
                    {current < endNumber && <>
                        <li>
                            <a href="javascript:void(0)" onClick={() => this.props.onLoadMore('next')}>
                                <span aria-hidden="true">›</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" onClick={() => this.props.onLoadMore('last')}>
                                <span aria-hidden="true">»</span>
                            </a>
                        </li>
                    </>}
                </ul>
            )
        }
        return null
    }

    renderHeader() {
        const { sorting, hideColumns, categoryColumnType } = this.props
        const hideCategory = hideColumns ? hideColumns.category : false
        return (
            <div className="listview-row listview-header">
                <div className="play-column" />
                <div className="title-artist-column"
                    onClick={() => this.changeSortBy('title')}>
                    {'TITLE'}
                    <img className={sorting === SortByQueryOptions.TitleDesc ? "chevron rotate-down" : (sorting === SortByQueryOptions.TitleAsc ? 'chevron' : 'chevron hidden')}
                        src={Assets.CHEVRON_LEFT} alt="chevron" />
                </div>

                <div className="title-artist-column artist" onClick={() => this.changeSortBy('artist')}>
                    {'ARTIST'}
                    <img className={sorting === SortByQueryOptions.ArtistDesc ? "chevron rotate-down" : (sorting === SortByQueryOptions.ArtistAsc ? 'chevron' : 'chevron hidden')}
                        src={Assets.CHEVRON_LEFT} alt="chevron" />
                </div>

                <div className="bpm-column" onClick={() => this.changeSortBy('bpm')}>
                    {'BPM'}
                    <img className={sorting === SortByQueryOptions.BPMDesc ? "chevron rotate-down" : (sorting === SortByQueryOptions.BPMAsc ? 'chevron' : 'chevron hidden')}
                        src={Assets.CHEVRON_LEFT} alt="chevron" />
                </div>

                {!hideCategory && <div className="genre-column">{categoryColumnType == 'genre' ? 'GENRE' : 'CATEGORY'}</div>}
                <div className="date-column" onClick={() => this.changeSortBy('date')}>
                    {'DATE'}
                    <img className={sorting === SortByQueryOptions.DateDesc ? "chevron rotate-down" : (sorting === SortByQueryOptions.DateAsc ? 'chevron' : 'chevron hidden')}
                        src={Assets.CHEVRON_LEFT} alt="chevron" />
                </div>

                <div className="key-column" onClick={() => this.changeSortBy('key')}>
                    {'KEY'}
                    <img className={sorting === SortByQueryOptions.KeyDesc ? "chevron rotate-down" : (sorting === SortByQueryOptions.KeyAsc ? 'chevron' : 'chevron hidden')}
                        src={Assets.CHEVRON_LEFT} alt="chevron" />
                </div>
                <div className="type-column" />
            </div>
        )
    }

})

class ListViewItem extends React.Component<{
    album: Album,
    playerContext: PlayerContext
    chordType: 'minor' | 'major'
    onPlayPauseClicked: (e: React.MouseEvent<HTMLElement, MouseEvent>, album: Album) => void
    onDownloadClicked: (e: React.MouseEvent<HTMLElement, MouseEvent>, track: Track) => Promise<any>
    onLikeClicked: (e: React.MouseEvent<HTMLElement, MouseEvent>, album: Album) => Promise<any>
    onCategoryClicked: (c: Category) => void
    onBPMClicked: (bpm: number) => void
    onKeyClicked: (key: string) => void
    onDateClicked: (date: string) => void
    onArtistClick: (artist: string) => void
    onExclusiveClick: () => void

    hideColumns?: HideColumns
    categoryColumnType: 'genre' | 'category'
}, {
}> {
    constructor(p: any) {
        super(p);
        this.state = {
            favoriting: false
        }
    }

    getMajorKey(key: string) {
        const keyFromDictionary = CamelotMapping[key]
        if (keyFromDictionary) return keyFromDictionary
        else return key
    }

    // AKA retrieve keys from dictionary by their value
    getMinorKey(dictionary: { [key: string]: string }, value: string) {
        const keyFromDictionary = Object.keys(dictionary).find(key => dictionary[key] === value);
        if (keyFromDictionary) return keyFromDictionary
        else return value
    }

    public static renderArtistLink(album: Album, onArtistClick?: (artist: string) => void) {
        if (album.artist_info) {
            let artist = album.artist
            let linkArray = album.artist_info.map((info, i) => {
                artist = artist.replace(info, '{ʥ}')
                return {
                    name: info, link: <span key={info + i} className='link' onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onArtistClick && onArtistClick(info)
                    }}>{info}</span>
                }
            }).sort((a1, a2) => {
                return album.artist.toLowerCase().indexOf(a1.name.toLowerCase()) - album.artist.toLowerCase().indexOf(a2.name.toLowerCase())
            })
            let elements = new Array<JSX.Element>()
            let match
            let re = /{ʥ}/g
            let index = 0
            do {
                match = re.exec(artist);
                if (match) {
                    elements.push(linkArray[index++].link)
                    elements.push(<span className='artist-link-text' key={match.index}>{artist.substr(match.index + '{ʥ}'.length).slice(0, artist.substr(match.index + '{ʥ}'.length).indexOf('{ʥ}'))}</span>)
                }
            } while (match);
            return elements
        } else {
            return <span className='link' onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onArtistClick && onArtistClick(album.artist)
            }}>{album.artist}</span>
        }
    }

    render() {
        const { album, playerContext, onBPMClicked, onCategoryClicked, onKeyClicked, onDateClicked,
            onArtistClick, hideColumns, categoryColumnType } = this.props
        const hideCategory = hideColumns ? hideColumns.category : false
        return <AlbumWrapper album={album}>{(album) => {
            const cleanTrack = album.Tracks.find(t => t.id == album.clean_version_id)
            const dirtyTrack = album.Tracks.find(t => t.id == album.dirty_version_id)
            const isPlaying = playerContext.state == PlayerState.Playing && album.id == playerContext.album?.id
            return (
                <div className="listview-row listview-item">
                    <div className="play-column">
                        <img alt='' src={isPlaying ? Assets.ICON_PAUSE : Assets.ICON_PLAY} className="play-icon clickable" onClick={(e) => {
                            this.props.onPlayPauseClicked(e, album)
                        }} />
                        {isPlaying && <img alt='' src={Assets.PLAYING} className="play-icon playing" />}
                    </div>
                    <div className="title-artist-column">{album.name}</div>
                    <div className="title-artist-column">{ListViewItem.renderArtistLink(album, onArtistClick)}</div>



                    <img className={album.exclusive === true ? 'exclusive-svg link' : 'exclusive-svg hidden'} src={Assets.EXCLUSIVE_TAG} alt="exclusive" onClick={() => {
                        this.props.onExclusiveClick()
                    }} />
                    <div className="bpm-column link" onClick={() => onBPMClicked(album.bpm)}>{album.bpm}</div>
                    {!hideCategory && <div className="genre-column link" onClick={() => onCategoryClicked(album.Category)}>{categoryColumnType == 'genre' ? (album.Genre ? album.Genre.name : '') : album.Category.name}</div>}
                    <div className="date-column link" onClick={() => onDateClicked(album.created_at)}>{moment(album.created_at).format('YYYY-MM-DD')}</div>

                    <div className="key-column link" >
                        <div className="key" onClick={() =>
                            onKeyClicked(album.key)}>{this.props.chordType === 'minor'
                                ? this.getMinorKey(CamelotMapping, album.key)
                                : this.getMajorKey(album.key)}
                        </div>
                        <div className="key-hover">
                            {this.props.chordType === 'minor'
                                ? this.getMajorKey(album.key)
                                : this.getMinorKey(CamelotMapping, album.key)}
                        </div>
                    </div>

                    <div className="type-column">
                        {cleanTrack ?
                            <div className={"type-icon clickable " + (cleanTrack.download_count > 0 ? 'downloaded' : '')} onClick={(e) => {
                                this.setState({ downloading_clean: true })
                                this.props.onDownloadClicked(e, cleanTrack).finally(() => {
                                    this.setState({ downloading_clean: false })
                                })
                            }}>
                                <img alt='' src={cleanTrack.download_count > 0 ? Assets.ICON_CLEAN_ORANGE : Assets.ICON_CLEAN} className={"icon"} />
                            </div>
                            :
                            <div className="type-icon" />}
                        {dirtyTrack ?
                            <div className={"type-icon clickable " + (dirtyTrack.download_count > 0 ? 'downloaded' : '')} onClick={(e) => this.props.onDownloadClicked(e, dirtyTrack)}>
                                <img alt='' src={dirtyTrack.download_count > 0 ? Assets.ICON_DIRTY_ORANGE : Assets.ICON_DIRTY} className={"icon"} />
                            </div>
                            :
                            <div className="type-icon" />}
                        <div className="type-icon liked clickable" onClick={(e) => {
                            this.setState({ favoriting: true })
                            this.props.onLikeClicked(e, album).finally(() => {
                                this.setState({ favoriting: false })
                            })
                        }}>
                            <img alt='' src={album.is_favorited ? Assets.ICON_LIKED_SONGS_ORANGE_FILLED : Assets.ICON_LIKED_SONGS_WHITE} className="icon" />
                        </div>
                    </div>
                </div>
            )
        }}</AlbumWrapper>
    }
}