import { I18nResolver } from 'i18n-ts'

const en = {
    //Login Screen
    SignIn: 'Sign in',
    Reset: 'Reset',
    Email: 'Email',
    EmailAddress: 'Email address',
    Password: 'Password',
    KeepMeSignedIn: 'keep me signed in',
    ForgotenPassword: 'Forgot password',
    ResetPassword: 'Reset Passwod',
    NoAccount: 'Don\’t have a BREAKSRUS account?',
    BackToLogin: 'Back to Login',
    CreateAccount: 'Create Account',
    EmailPasswordNotValid: 'Email or password not valid',
    PasswordNotValid: 'Password not valid',
    EmailMissing: 'Please enter your email address',
    EmailNotFound: 'We couldn\’t find your email',
    ForgotPWEmailSent: 'We sent you a email to reset your password.',

    //SignUp Screen
    JoinClubsets: 'Join BREAKSRUS',
    FullName: 'Full Name',
    SignUp: 'Sign Up',
    AgreeTermsCond: 'I agree to BREAKSRUS',
    Terms: ` Terms`,
    PrivacyPolicy: 'Privacy Policy',
    AlreadyMember: 'Already a member? ',
    LogIn: 'Log in',
    EnterName: 'Please enter your full name',
    MustAgree: 'You must agree our Terms & Privacy Policy',

    //Membership
    SelectPremium: 'Select Premium',
    ReviewAndPay: 'Review and pay',
    StartClubsets: 'Let’s get you started with BREAKSRUS',
    MembershipInfo: 'Our membership gives you access to a selected arsenal of club music curated specifically for your prime set.',
    GoPremium: 'Go Premium',
    PremiumInfo: 'Billed every month until you decide to cancel. Cancel at anytime.',
    TrialDescription: 'First 14 days are free!',
    GetStarted: 'Get Started',
    CreditCardInfo: 'Credit Card Info',
    Subscribe: 'Subscribe',

    //Settings
    Account: 'Account',
    Billing: 'Billing',
    RecordPool: 'Record Pool',
    ProfilePicture: 'Profile Picture',
    Address: 'Address',
    Security: 'Security',
    CurrentPassword: 'Current Password',
    ChangePassword: 'Change Password',
    Update: 'Update',
    EmailNotValid: 'Email not valid',
    CurrentPlan: 'Current Plan',
    StartDate: 'Start Date',
    NotSubscribed: 'Not Subscribed',
    NoPaymentMethod: 'No Payment Method',
    NextBilling: 'Next Billing Date',
    Amount: 'Amount',
    CancelAccount: 'Cancel Account',
    PaymentMethod: 'Payment Method',
    CardType: 'Card Type',
    CreditCard: 'Credit card',
    ChangePaymentMethod: 'Change Payment Method',
    Receipts: 'Receipts',
    Date: 'Date',
    Reference: 'Reference',
    Price: 'Price',
    View: 'View',
    LoadMore: 'Load more',
    SaveSettings: 'Save Settings'
}

const i18n = {
    en: en,
    default: en
}

export const translations = new I18nResolver(i18n).translation;