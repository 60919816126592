import DynamicPlaylist, { PlaybackMode } from "./DynamicPlaylist";
import PlaybackManager from "./PlaybackManager";
import { FullPlaybackMediaInfo } from "./types";
const uuidv4 = require('uuid/v4');

export type ActiveListChangedListener = (tracks: FullPlaybackMediaInfo[]) => void;

/**
 * This enum is used to identify different types of playable items
 */
export enum PlaybackListItemType {
    Playlist = 'playlist',
    Track = 'track',
    CuratedSet = 'curated_set'
}

/**
 * This interface is a container for a playable item
 */
export interface PlaybackListItem {
    type: PlaybackListItemType
    item: DynamicPlaylist
}

/**
 * This class manages a queue of playable items and their playback
 */
export default class PlaybackList {

    private _tracks: FullPlaybackMediaInfo[] = []
    private _shuffledTracks: FullPlaybackMediaInfo[] = []
    private _playbackMode: PlaybackMode = 'serial'
    private _activeListListeners: { [key: string]: ActiveListChangedListener } = {};

    // Playlist Index Getters

    /**
     * Returns the index of the given track
     * @param track 
     * @param raw This flag determines if the input list of tracks should be used (false) or the playback mode (shuffle/serial) should be taken into account (true)
     */
    public getTrackIndex(track: FullPlaybackMediaInfo, raw: boolean = false): number {
        return (raw ? this._tracks : this.getTracks()).findIndex((item) => {
            return item.album.Tracks.map(i => i.id).includes(track.track.id) && item.playlist.isEqual(track.playlist)
        })
    }

    /**
     * Returns the index of the given track in the shuffled list
     * @param track 
     */
    public getShuffledTrackIndex(track: FullPlaybackMediaInfo): number {
        return this._shuffledTracks.findIndex((item) => {
            return item.album.Tracks.map(i => i.id).includes(track.track.id) && item.playlist.isEqual(track.playlist)
        })
    }

    // Track Getters

    /**
     * Returns the upcoming list of tracks after the given track
     * @param song 
     */
    public getTracksAfter(song: FullPlaybackMediaInfo | undefined) {
        let idx = song ? this.getTrackIndex(song) : 0
        return this.getTracks().slice(idx + 1);
    }

    /**
     * Returns the previous list of tracks after the given track
     * @param song 
     */
    public getTracksBefore(song: FullPlaybackMediaInfo | undefined) {
        let idx = song ? this.getTrackIndex(song) : 0
        return this.getTracks().slice(0, idx);
    }

    /**
     * Returns the track after the current track
     * @param song 
     */
    public getTrackAfter(song?: FullPlaybackMediaInfo): FullPlaybackMediaInfo | undefined {
        let idx = song ? this.getTrackIndex(song) : 0
        if (idx + 1 < this.getTracks().length) {
            return this.getTracks()[idx + 1]
        }
        return undefined
    }

    /**
     * Returns the track before the current track
     * @param song 
     */
    public getTrackBefore(song?: FullPlaybackMediaInfo): FullPlaybackMediaInfo | undefined {
        let idx = song ? this.getTrackIndex(song) : 0
        if (idx - 1 >= 0) {
            return this.getTracks()[idx - 1]
        }
        return undefined
    }

    // Util Getters

    /**
     * Returns all tracks that have been enqueued into the playback list
     */
    public getTracks() {
        return this._playbackMode === 'serial' ? this._tracks : this._shuffledTracks
    }

    /**
     * Returns the current playback mode
     */
    public getPlaybackMode(): PlaybackMode {
        return this._playbackMode
    }

    /**
     * Sets the current playback mode
     */
    public setPlaybackMode(mode: PlaybackMode) {
        this._shuffledTracks = this.shuffle(this._tracks);
        this._playbackMode = mode;
    }

    // Playlist Management


    /**
     * Appends a list to play after the current list
     */
    public appendNext(list: DynamicPlaylist) {
        let track = PlaybackManager.getInstance().getTrack();
        if (track) {
            let index = this.getTrackIndex(track)
            this.insertList(list, index + 1)
        } else {
            this.insertList(list, 0)
            PlaybackManager.getInstance().startTrack(this.getTracksAfter(undefined)[0])
        }
    }

    /**
     * Replaces all currently enqueued lists with the given list
     * @param list
     */
    public replaceLists(list: DynamicPlaylist) {
        this._tracks = list.tracks()
        this._shuffledTracks = this.shuffle(this._tracks)
        this.callActiveListChangedListeners(this._tracks)
    }

    /**
     * Appends a list for playback
     * @param list
     */
    public appendList(list: DynamicPlaylist) {
        if (this._tracks.find(t => t.playlist.context == list.context)) {
            return
        }
        this._tracks = this._tracks.concat(list.tracks())
        this._shuffledTracks = this._shuffledTracks.concat(this.shuffle(list.tracks()))
        this.callActiveListChangedListeners(this._tracks)
    }

    /**
     * Inserts a list for playback at the given index
     * @param list
     */
    public insertList(list: DynamicPlaylist, atIndex: number) {
        this._tracks.splice(atIndex, 0, ...list.tracks())
        this._shuffledTracks.splice(atIndex, 0, ...this.shuffle(list.tracks()))
        this.callActiveListChangedListeners(this._tracks)
    }

    /**
     * Removes a track from the playback queue
     * @param list
     */
    public removeTrack(track: FullPlaybackMediaInfo) {
        let idx = this.getTrackIndex(track)
        this._tracks.splice(idx, 1)
        let idx2 = this.getShuffledTrackIndex(track)
        this._shuffledTracks.splice(idx2, 1)
        this.callActiveListChangedListeners(this._tracks)
    }

    // Change Listeners

    /**
     * Adds a listener for any changes in the active ilst
     * @param list
     */
    public addActiveListChangedListener(listener: ActiveListChangedListener): string {
        let id = uuidv4();
        this._activeListListeners[id] = listener;
        return id;
    }

    /**
     * Removes a listener for any changes in the active ilst
     * @param list
     */
    public removeActiveListChangedListener(id: string) {
        delete this._activeListListeners[id];
    }

    private callActiveListChangedListeners(tracks: FullPlaybackMediaInfo[]) {
        Object.keys(this._activeListListeners).map((key) => { return this._activeListListeners[key] }).forEach((cb) => {
            cb(tracks);
        })
    }

    private shuffle<T>(inputdata: T[]): T[] {
        let input = ([] as T[]).concat(...inputdata)
        let track = PlaybackManager.getInstance().getTrack()
        let trackIndex = track && this.getTrackIndex(track)
        let playingTrack = trackIndex !== undefined && trackIndex >= 0 && input.splice(trackIndex, 1);

        let output = input.slice();
        for (var i = output.length - 1; i >= 0; i--) {

            var randomIndex = Math.floor(Math.random() * (i + 1));
            var itemAtIndex = output[randomIndex];

            output[randomIndex] = output[i];
            output[i] = itemAtIndex;
        }
        trackIndex !== undefined && trackIndex >= 0 && playingTrack && playingTrack.length == 1 && output.splice(trackIndex, 0, ...playingTrack)
        return output;
    }
}
