import * as React from 'react'
import ClubsetsHeader from '../Components/ClubsetsHeader';
import { UserContextConsumer, UserContextType } from '../context/user';
import { SettingsHeader } from '../Components/SettingsHeader';
import { SettingsNavItem } from '../api/models/settings';
import { translations } from '../translations';
import { Colors } from '../assets';
import { Assets } from '../assets/index';
import { ClubsetsButton } from '../Components/ClubsetsButton';
import isEmail from 'validator/lib/isEmail';
import { User } from '../api/models/user';
import { AudioVideoProps } from '../api/models/media';
import { Category } from '../api/models/category';
import { MediaAPI } from '../api/endpoints/media';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Subscription, UserAPI, Card, Invoice } from '../api/endpoints/user';
import { ClubsetsLoader } from '../Components/ClubsetsLoader';
import { errorToString } from '../api/errors';
import * as toastr from 'toastr'
import { FAQ } from '../Components/FAQ';
interface SettingsProps extends AudioVideoProps, RouteComponentProps {

}

export const Settings = withRouter(class Settings extends React.Component<SettingsProps, {

    subscriptionLoading: boolean
    subscription?: Subscription

    cardLoading: boolean
    card?: Card

    invoiceLoading: boolean
    invoices: Invoice[]

    content: SettingsNavItem
    fullname: string
    email: string

    uploading: boolean
    passwordLoading: boolean

    categories: Category[]
    chordType: 'major' | 'minor'
    hidden_categories: Category[]
}> {
    cancelButton: ClubsetsButton | null;

    static contextType = UserContextType
    context: User | null
    previousContext: User | null = this.context
    fileInput: HTMLInputElement | null;

    constructor(props: SettingsProps) {
        super(props);
        this.state = {
            subscriptionLoading: true, cardLoading: true, invoiceLoading: true, invoices: [], uploading: false, passwordLoading: false,
            content: props.location.pathname == '/billing' ? 'billing' : 'account',
            fullname: this.context!?.full_name || '',
            email: this.context?.email || '',
            categories: [],
            hidden_categories: this.context!?.hidden_categories || [],
            chordType: 'major',
        }
    }

    componentDidUpdate(prevProps: SettingsProps) {
        if (prevProps.location.pathname != this.props.location.pathname) {
            this.setState({ content: this.props.location.pathname == '/billing' ? 'billing' : 'account' })
        }
        if (this.previousContext != this.context) {
            this.setState({ email: this.context!.email, fullname: this.context!.full_name, hidden_categories: this.context!.hidden_categories })
            this.previousContext = this.context
        }
    }

    componentDidMount() {
        this.load()
        MediaAPI.getCategories().then(categories => {
            this.setState({ categories })
        })
    }

    load() {
        UserAPI.getSubscription().then(subscription => {
            this.setState({ subscription, subscriptionLoading: false })
        }).catch(err => {
            this.setState({ subscriptionLoading: false })
        })
        UserAPI.getCreditCard().then(card => {
            this.setState({ card, cardLoading: false })
        }).catch(err => {
            this.setState({ cardLoading: false })
        })
        UserAPI.getInvoices().then(invoices => {
            this.setState({ invoices, invoiceLoading: false })
        }).catch(err => {
            this.setState({ invoiceLoading: false })
        })
    }

    private button: ClubsetsButton | null = null


    render() {
        return (
            <UserContextConsumer>{(user) =>
                <div className="settings-container">
                    <ClubsetsHeader
                        type={'logo'}
                        profileImageUrl={user && user.profile_image_thumbnail_url}
                    />
                    <SettingsHeader active={this.state.content}
                        onNavItemClicked={(item) => this.onNavitemClicked(item)}
                    />
                    <div className="content-container">
                        {this.state.content === 'account' ?
                            this.renderAccountContent(user)
                            :
                            this.renderBillingContent(user)
                        }
                    </div>
                    <FAQ />
                </div>
            }
            </UserContextConsumer>
        )
    }

    private renderAccountContent(user: User) {
        return (
            <div className="content">
                <div className="headline">
                    <span>{translations.Account.toUpperCase()}</span>
                </div>
                <input ref={(ref) => this.fileInput = ref} accept='image/*' type='file'
                    style={{ visibility: 'hidden', width: 0, height: 0, position: 'absolute', top: -5 }} onChange={(e) => {
                        let file = e.target.files!.length > 0 ? e.target.files![0] : undefined
                        if (file) {
                            this.setState({ uploading: true })
                            UserAPI.uploadProfilePicture(file).then(() => {
                                this.setState({ uploading: false })
                                toastr.success('Profile image uploaded!')
                            }).catch(err => {
                                this.setState({ uploading: false })
                                errorToString(err).forEach(error => {
                                    toastr.error(error)
                                })
                            })
                        }
                        e.target.value = ''
                    }} />
                <div className="account-section">
                    <span className="title">{translations.ProfilePicture}</span>
                    {this.state.uploading && <span className="profile-image"><ClubsetsLoader /></span>}
                    {!this.state.uploading && <>
                        {user.profile_image_thumbnail_url ?
                            <img className="profile-image" alt="profile-img" src={user.profile_image_thumbnail_url} onClick={() => {
                                this.fileInput?.click()
                            }} />
                            :
                            <div className="profile-image" style={{ backgroundColor: Colors.GREY }} onClick={() => {
                                this.fileInput?.click()
                            }} />
                        }
                    </>}
                </div>
                <div className="account-section">
                    <span className="title">{translations.FullName}</span>
                    <input
                        type={'text'}
                        className="input"
                        value={this.state.fullname.length > 0 ? this.state.fullname : user.full_name}
                        placeholder={translations.FullName}
                        onChange={(e) => { this.setState({ fullname: e.target.value }) }}
                        onFocus={() => { this.button && this.button.hideTooltip() }}
                    />
                </div>
                <div className="account-section">
                    <span className="title">{translations.Email}</span>
                    <input
                        type={'text'}
                        className="input"
                        value={this.state.email.length > 0 ? this.state.email : user.email}
                        placeholder={translations.Email}
                        onChange={(e) => { this.setState({ email: e.target.value }) }}
                        onFocus={() => { this.button && this.button.hideTooltip() }}
                    />
                </div>
                <div className="update">
                    <ClubsetsButton
                        ref={(ref) => this.button = ref}
                        text={translations.Update}
                        width={141}
                        onPress={() => this.updateProfile()}
                    />
                </div>
                <div className="headline" style={{ marginTop: 40 }}>
                    <span>{translations.Security.toUpperCase()}</span>
                </div>
                <div className="account-section bottom-border">
                    <span className="title">{translations.CurrentPassword}</span>
                    {this.state.passwordLoading && <span><ClubsetsLoader /></span>}
                    {!this.state.passwordLoading && <span className="text" onClick={() => this.changePassword()}>{translations.ChangePassword.toUpperCase()}</span>}
                </div>

                <div className="headline bottom-border">DISPLAY OPTIONS</div>
                <ul className="categories">
                    {
                        this.state.categories.map(category => {
                            return (
                                <li>
                                    <div>{category.name}</div>
                                    <div className="switch-container">
                                        <input
                                            className="switch"
                                            type="checkbox" checked={this.state.hidden_categories.find(c => c.id == category.id) != undefined}
                                            onChange={() => {
                                                let hiddenItems = this.state.hidden_categories
                                                if (this.state.hidden_categories.find(c => c.id == category.id)) {
                                                    hiddenItems = hiddenItems.filter(f => f.id != category.id)
                                                } else {
                                                    hiddenItems.push(category)
                                                }

                                                this.setState({ hidden_categories: hiddenItems })
                                                const ids = hiddenItems.map(hiddenItem => {
                                                    return hiddenItem.id
                                                })
                                                UserAPI.setCategoriesHidden(ids).then(() => {
                                                    toastr.success('Settings updated!')
                                                }).catch(err => {
                                                    errorToString(err).forEach(error => {
                                                        toastr.error(error)
                                                    })
                                                })
                                            }}
                                        />
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="headline bottom-border">APPEARANCE</div>
                <div className="themes-container">
                    <div className="theme-container" >
                        <img src={Assets.IMAGE_LIGHT_THEME} alt="light theme" onClick={() => {
                            if (this.context) {
                                let newTheme = this.context.theme === 'dark' ? 'light' : 'dark'
                                this.updateSetting({ theme: newTheme })
                                document.body.classList.toggle('day-mode')
                            }
                        }} />
                        <div className="flex">
                            <input type="radio" id="light" name="theme" value="light"
                                checked={this.context && this.context.theme === 'light' ? true : false}
                                onClick={() => {
                                    if (this.context) {
                                        let newTheme = this.context.theme === 'dark' ? 'light' : 'dark'
                                        this.updateSetting({ theme: newTheme })
                                        document.body.classList.toggle('day-mode')
                                    }
                                }}
                            />

                            <label>Light</label>
                        </div>
                    </div>

                    <div className="theme-container">
                        <img src={Assets.IMAGE_DARK_THEME} alt="dark theme" onClick={() => {
                            if (this.context) {
                                let newTheme = this.context.theme === 'light' ? 'dark' : 'light'
                                this.updateSetting({ theme: newTheme })
                                document.body.classList.toggle('day-mode')
                            }
                        }} />
                        <div className="flex">
                            <input type="radio" id="dark" name="theme" value="dark"
                                checked={this.context && this.context.theme === 'dark' ? true : false}
                                onClick={() => {
                                    if (this.context) {
                                        let newTheme = this.context.theme === 'light' ? 'dark' : 'light'
                                        this.updateSetting({ theme: newTheme })
                                        document.body.classList.toggle('day-mode')
                                    }
                                }}
                            />
                            <label>Dark</label>
                        </div>
                    </div>
                </div>
                <div className="headline">CHORD TYPE</div>
                <div className="chord-type-container">
                    <div>Key</div>
                    <div className="switch-container">
                        <input
                            className="switch"
                            type="checkbox"
                            onClick={() => {
                                let newType = this.state.chordType === 'major' ? 'minor' : 'major'
                                this.updateSetting({ chord_setting: newType })
                                this.setState({ chordType: newType as 'major' | 'minor' })
                            }}
                        />
                        <span className="switch-text">{this.state.chordType === 'major' ? 'Camelot Key' : 'Root Key'}</span>
                    </div>
                </div>
            </div>
        )
    }

    private renderBillingContent(user: User) {
        return (
            <div className="content">
                {/* CurrentPlan Section */}
                <div className="headline billing-mg">
                    <span>{translations.CurrentPlan.toUpperCase()}</span>
                </div>
                {this.state.subscriptionLoading && <ClubsetsLoader />}
                {!this.state.subscriptionLoading && this.state.subscription && <div className="billing-section billing-mg">
                    <div className="column column-1">
                        <div className="head">
                            <span>{translations.StartDate}</span>
                        </div>
                        <span>{new Date(this.state.subscription.start_date).toLocaleDateString()}</span>
                    </div>
                    <div className="column column-2">
                        <div className="head">
                            <span>{translations.NextBilling}</span>
                        </div>
                        <span>{this.state.subscription.canceled_date ? ('Canceled, expires ' + new Date(this.state.subscription.canceled_date).toLocaleDateString()) : new Date(this.state.subscription.renewal_date).toLocaleDateString()}</span>
                    </div>
                    <div className="column column-3">
                        <div className="head">
                            <span>{translations.Amount}</span>
                        </div>
                        <span>${this.state.subscription.canceled_date ? '0.00' : (this.state.subscription.amount_paid / 100).toFixed(2)}</span>
                    </div>
                </div>}
                {!this.state.subscriptionLoading && !this.state.subscription && <div className="billing-section billing-mg">
                    <div className="column column-1">
                        <div className="head">
                            <span>{translations.NotSubscribed}</span>
                        </div>
                    </div>
                </div>}
                {!this.state.subscriptionLoading && (!(this.state.subscription && this.state.subscription.canceled_date)) && <div className="billing-button bottom-border">
                    <ClubsetsButton
                        text={!this.state.subscription ? translations.GetStarted : translations.CancelAccount}
                        onPress={() => {
                            if (!this.state.subscription) {
                                this.props.history.push('/subscribe')
                            } else
                                this.cancelAccount()
                        }}
                    />
                </div>}
                {/* Payment Method Section */}
                <div className="headline billing-mg">
                    <span>{translations.PaymentMethod.toUpperCase()}</span>
                </div>
                {this.state.cardLoading && <ClubsetsLoader />}
                {!this.state.cardLoading && this.state.card && <div className="billing-section billing-mg">
                    <div className="column column-1">
                        <div className="head">
                            <span>{translations.FullName}</span>
                        </div>
                        <span>{user.full_name}</span>
                    </div>
                    <div className="column column-2">
                        <div className="head">
                            <span>{translations.CardType}</span>
                        </div>
                        <span>{this.state.card.brand.slice(0, 1).toUpperCase()}{this.state.card.brand.slice(1)}</span>
                    </div>
                    <div className="column column-3">
                        <div className="head">
                            <span>{translations.CreditCard}</span>
                        </div>
                        <span>ending in {this.state.card.last4}</span>
                    </div>
                </div>}
                {!this.state.cardLoading && !this.state.card && <div className="billing-section billing-mg">
                    <div className="column column-1">
                        <div className="head">
                            <span>{translations.NoPaymentMethod}</span>
                        </div>
                    </div>
                </div>}
                {/*<div className="billing-button bottom-border">
                    <ClubsetsButton
                        text={translations.ChangePaymentMethod}
                        onPress={() => this.changePaymentMethod()}
                    />
                    </div>*/}
                {/* Receipts Section */}
                <div className="headline billing-mg">
                    <span>{translations.Receipts.toUpperCase()}</span>
                </div>
                {this.state.invoiceLoading && <ClubsetsLoader />}
                {!this.state.invoiceLoading && <div className="receipts-container">
                    <div className="receipts-row receipts-header">
                        <span className="column column-1">{translations.Date}</span>
                        <span className="column column-2">{translations.Reference}</span>
                        <span className="column column-3">{translations.Price}</span>
                        <span className="column-4" />
                    </div>
                    {this.state.invoices.map((receipt, index) => {
                        return (
                            <div className={`receipts-row${index % 2 === 0 ? ' black' : ''}`} key={receipt.id}>
                                <span className="column column-1">{new Date(receipt.created).toLocaleDateString()}</span>
                                <span className="column column-2">{receipt.id}</span>
                                <span className="column column-3">{`$${(receipt.total / 100).toFixed(2)}`}</span>
                                <span className="column-4" onClick={() => { this.onReceiptClick(receipt) }}>{translations.View}</span>
                            </div>
                        )
                    })}
                </div>}
                {/*<div className="billing-button">
                    <ClubsetsButton ref={ref => this.cancelButton = ref}
                        text={translations.LoadMore}
                        onPress={() => this.loadMoreReceipts()}
                    />
                </div>*/}
            </div>
        )
    }

    private onNavitemClicked(item: SettingsNavItem) {
        this.props.history.push('/' + item)
    }

    private changePassword() {
        this.setState({ passwordLoading: true })
        UserAPI.resetMyPassword().then(() => {
            toastr.success(translations.ForgotPWEmailSent)
            this.setState({ passwordLoading: false })
        }).catch(err => {
            errorToString(err).forEach(error => {
                toastr.error(error)
            })
            this.setState({ passwordLoading: false })
        })
    }

    private updateSetting(setting: {}) {
        UserAPI.updateMe(setting).then(() => {
            toastr.success('Account updated!')
        }).catch(err => {
            errorToString(err).forEach(error => {
                toastr.error(error)
            })
        })
    }

    private updateProfile() {
        if (this.button) {
            this.button.loading(true)
            this.button.hideTooltip()
        }
        let error: string | undefined = undefined
        if (this.state.fullname.trim() === '' || this.state.fullname.split(' ').length < 2) {

            error = translations.EnterName
        } else if (!isEmail(this.state.email)) {
            error = translations.EmailNotValid

        }
        if (error) {
            if (this.button) {
                this.button.loading(false)
                this.button.showTooltip(error)
            }
        } else {
            this.button?.loading(true)
            UserAPI.updateMe({ full_name: this.state.fullname, email: this.state.email, chord_setting: this.state.chordType }).then(() => {
                toastr.success('Account updated!')
                this.button?.loading(false)
            }).catch(err => {
                errorToString(err).forEach(error => {
                    toastr.error(error)
                })
                this.button?.loading(false)
            })
        }
    }

    private cancelAccount() {
        this.cancelButton?.loading(true)
        UserAPI.cancelSubscription().then(() => {
            toastr.success('Successfully canceled your subscription')
            this.load()
            this.cancelButton?.loading(false)
        }).catch(err => {
            this.cancelButton?.loading(false)
            this.cancelButton?.showTooltip(errorToString(err).join('\n'))
        })
    }

    private changePaymentMethod() {

    }

    private onReceiptClick(receipt: Invoice) {
        window.location.assign(receipt.invoice_pdf)
    }

    private loadMoreReceipts() {

    }
})

interface Receipt {
    date: string,
    reference: string,
    price: number
}