import React from 'react';
import { RouteComponentProps } from 'react-router';
import { UserContextProvider } from './context/user';
import { User } from './api/models/user';
import { ClubsetsLoader } from './Components/ClubsetsLoader';
import { AudioVideoProps } from './api/models/media';
import { Premium } from './views/Premium';
import { Settings } from './views/Settings';
import DashBoard from './views/DashBoard';
import { UserAPI } from './api/endpoints/user';
import Player from './player/Player';
import { PlaybackManager, PlayerState, PlaybackState } from './player';
import { PlayerContextProvider } from './context/player';
import { MediaAPI } from './api/endpoints/media';
import AlbumManager from './api/albummanager';
import { Track } from './api/models/track';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Environment } from './env';
import { Session } from './api/requests/request';

const stripePromise = loadStripe(Environment.STRIPE);

const defaultProps: AudioVideoProps = {
    onTrackDownload: (_, track: Track) => {
        return MediaAPI.download(track).then(({ album, url }) => {
            AlbumManager.getInstance().updateAlbum(album)
            window.location.assign(url)
            return true
        })
    }, onMediaSelected: () => {

    }, onAlbumSelected: (album) => {

    }, onAlbumLikeToggled: (album) => {
        if (album.is_favorited) {
            return MediaAPI.unlike(album).then((album) => {
                AlbumManager.getInstance().updateAlbum(album)
                return album
            })
        } else {
            return MediaAPI.like(album).then((album) => {
                AlbumManager.getInstance().updateAlbum(album)
                return album
            })
        }
    }
}

export default class App extends React.Component<RouteComponentProps<{ page: string }>, {
    user?: User
    player: {
        state: PlaybackState
    },
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            player: {
                state: {
                    state: PlayerState.None
                }
            },
        }
    }

    componentDidMount() {
        UserAPI.getMe().then(user => {
            if (user.theme == 'light') {
                document.body.classList.add('day-mode')
            }
        })
        Session.setUserUpdatedHandler((user) => {
            this.setState({ user })
        })
        PlaybackManager.getInstance().addPlaybackStateListener((state: PlaybackState) => {
            const player = Object.assign({}, this.state.player)
            player.state = state;
            this.setState({ player })
        })
    }

    private getMainComponent(page: string): React.ComponentClass<AudioVideoProps> {
        switch (page) {
            case 'account':
            case 'settings':
                return Settings;
            case 'billing':
                return Settings;
            case 'subscribe':
                return Premium
            default:
                return DashBoard;
        }
    }

    render() {
        const Comp = this.getMainComponent(this.props.match.params.page)
        if (!this.state.user) {
            return <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}><ClubsetsLoader size={80} /></div>
        }
        return (
            <UserContextProvider value={this.state.user}>
                <PlayerContextProvider value={{
                    track: this.state.player.state.media?.track,
                    album: this.state.player.state.media?.album,
                    state: this.state.player.state.state
                }}>
                    <Elements stripe={stripePromise}>
                        <Comp {...defaultProps} />
                    </Elements>
                    <Player {...defaultProps} />
                </PlayerContextProvider>
            </UserContextProvider>
        )
    }
}
