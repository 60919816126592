import * as React from 'react'
import { ClubsetsLoader } from './ClubsetsLoader';

interface ClubsetsButtonProps {
    text: string
    width?: number | string
    onPress: () => void
    disabled?: boolean
    style?: React.CSSProperties
}

export class ClubsetsButton extends React.Component<ClubsetsButtonProps, {
    loading: boolean
}> {

    constructor(props: ClubsetsButtonProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    render() {
        const { text, width, onPress, disabled, style } = this.props
        return (
            <>
                <button className="button" data-balloon-pos="down" data-balloon-length="fit" id="tooltip"
                    style={{ width: width, ...style }} onClick={() => {
                        if (!disabled && !this.state.loading) {
                            onPress()
                        }
                    }} disabled={disabled}>
                    {this.state.loading ?
                        <ClubsetsLoader
                            size={20}
                        //color={Colors.WHITE}
                        />
                        :
                        <span>{text}</span>
                    }
                </button>
            </>
        )
    }

    public showTooltip(error: string) {
        const tooltip = document.getElementById('tooltip');
        if (tooltip) {
            tooltip.setAttribute('aria-label', error);
            tooltip.setAttribute('data-balloon-visible', '');
        }
    }

    public hideTooltip() {
        const tooltip = document.getElementById('tooltip');
        if (tooltip) {
            tooltip.removeAttribute('aria-label');
            tooltip.removeAttribute('data-balloon-visible');
        }
    }

    public loading(loading: boolean) {
        this.setState({ loading })
    }
}