import DynamicPlaylist, { PlaybackMode } from "./DynamicPlaylist";
import PlaybackList from "./PlaybackList";
import PlaybackHistory from "./PlaybackHistory";
import { AUTOREPLAY_KEY, SHUFFLE_KEY, PlaybackStateListener, CookieProvider, PlaybackConfiguration, PlaybackProgressState, PartialFullPlaybackMediaInfo, PlaybackConfigListener, PlayerState, AddToHistory, FullPlaybackMediaInfo, PlaybackProgressStateListener, PlaybackState, HQ_KEY } from "./types";
import Player from "./Player";
import { Track } from "../api/models/track";
import { Album } from "../api/models/album";
const uuidv4 = require('uuid/v4');

/**
 * This class manages playback of various types of lists & tracks
 */
export default class PlaybackManager {

    // Shared Instance

    private static instance: PlaybackManager;
    private constructor() {
        this._playbackConfig = {
            shuffle: false,
            autorepeat: false,
            hq: localStorage[HQ_KEY] == 'true'
        }
        this.playbackConfig = {
            shuffle: (localStorage[SHUFFLE_KEY] == 'true'),
            autorepeat: (localStorage[AUTOREPLAY_KEY] == 'true'),
            hq: this.playbackConfig.hq
        }
    };

    /**
     * This returns the shared instance to use as the global PlaybackManager
     */
    public static getInstance(): PlaybackManager {
        if (PlaybackManager.instance) {
            return PlaybackManager.instance;
        }
        PlaybackManager.instance = new PlaybackManager();
        return PlaybackManager.getInstance();
    }

    // Properties

    private _playbackStateListeners: { [key: string]: PlaybackStateListener } = {};
    private _playbackConfigListeners: { [key: string]: PlaybackConfigListener } = {};
    private _cookieProvider?: CookieProvider;
    private _playbackState?: PlaybackProgressState;
    private _playbackConfig: PlaybackConfiguration;
    private _playbackContentManager: PlaybackList = new PlaybackList();
    public _internalPlayer?: Player;

    // Playback Configuration

    /**
     * This function starts playback of a track
     * @param trackData - The info about the track to play
     * @param resolveMedia - A function that resolves media urls if not provided in trackData
     * @param startPlayback - Defines if content should only be prepared but not played yet
     */
    public async startTrack(trackData: PartialFullPlaybackMediaInfo, startPlayback: boolean = true) {
        if (trackData == undefined) {
            return false
        }
        let track = trackData.track
        if (track === undefined) {
            track = trackData.album.Tracks[0];
        }

        if (!track) {
            return this.forward();
        }
        let data = Object.assign({}, trackData, { track: track });
        switch (trackData.playlist.historyMode()) {
            case AddToHistory.ALL:
            case AddToHistory.GLOBAL_ONLY:
            default:
                PlaybackHistory.getInstance().addTrack(data)
                break;
            case AddToHistory.LOCAL_ONLY:
                break;
        }

        let mediaToPlay = data.track
        if (mediaToPlay) {
            this.setPlaybackState({
                media: {
                    playlist: data.playlist,
                    track: mediaToPlay,
                    album: data.album,
                },
                state: startPlayback ? PlayerState.Loading : PlayerState.Paused
            }, data, true)
            try {
                if (startPlayback) {
                    this.startPlayback(data)
                }
            } catch (error) {

            }
        }
        return false
    }

    // Internal Playback State
    private startPlayback(media: FullPlaybackMediaInfo) {
        let { url, duration: oringial_duration } = this.getTrackUrl(media.track)
        if (url) {
            let additional_Info = {} as any
            if (oringial_duration) {
                additional_Info.duration = oringial_duration
            }
            this.setPlaybackState(Object.assign({}, this.playbackState, {
                media: Object.assign({}, media, {
                    ...additional_Info
                }),
            }), media)

            let play = async (url: string) => {
                this._internalPlayer && this._internalPlayer.play({
                    url: url, totalDuration: oringial_duration,
                    onError: async (type, data) => {
                        if (type == 'FileNotFound') {
                            if (this._cookieProvider) {
                                let available = await this._cookieProvider.isSet();
                                if (!available) {
                                    this.setPlaybackState(Object.assign({}, this.playbackState, {
                                        state: PlayerState.Loading
                                    }), media)
                                    try {
                                        await this._cookieProvider.set()
                                        this._cookieProvider.isSet()
                                        return this.startPlayback(media);
                                    } catch (error) {
                                        this.setPlaybackState(Object.assign({}, this.playbackState, {
                                            state: PlayerState.Error
                                        }), media)
                                        return false
                                    }
                                }
                            }
                            if ((<{ forceMP3: boolean }>media.track).forceMP3 == undefined && !this.isPlayingVideo()) {
                                (<{ forceMP3: boolean }>media.track).forceMP3 = true;
                                return this.startPlayback(media);
                            } else {
                                this.setPlaybackState(Object.assign({}, this.playbackState, {
                                    state: PlayerState.Error
                                }), media)
                                return false
                            }
                        } else {
                            this.setPlaybackState(Object.assign({}, this.playbackState, {
                                state: PlayerState.Error
                            }), media)
                            return false
                        }
                    },
                    onPlay: (trackDuration) => {
                        if (!oringial_duration)
                            this.setPlaybackState(Object.assign({}, this.playbackState, {
                                duration: trackDuration
                            }), media)
                    },
                    onProgress: (progress, trackDuration) => {
                        this.setPlaybackState(Object.assign({}, this.playbackState, {
                            progress: progress,
                            duration: oringial_duration || trackDuration
                        }), media)
                    },
                    onStateChange: (state) => {
                        this.setPlaybackState(Object.assign({}, this.playbackState, {
                            state: state
                        }), media)
                    },
                    onEnd: (target?: HTMLElement) => {
                        if (!this.autorepeat) {
                            let result = this.forward()
                            if (!result) {
                                this.setPlaybackState(Object.assign({}, this.playbackState, {
                                    state: PlayerState.None
                                }), media)
                            }
                        } else {
                            this.rewind(true);
                        }
                    }
                })

            }

            play(url);
            return true;
        } else {
            return false;
        }

    }

    /**
     * This function retrieves the appropriate content url for a track based on content type and the available quality
     * @param info 
     */
    public getTrackUrl(info: Track & { forceMP3?: boolean }): { url: string | undefined, duration: number | undefined } {
        return { url: info.stream_info[(!info.forceMP3 && Player.hlsSupported()) ? 'hls' : 'mp3'], duration: undefined }
    }

    // Playback Actions

    /**
     * This function pauses the currently playing track
     * @param cb - Called after pausing the track
     */
    public pause(cb?: () => void) {
        this._internalPlayer && this._internalPlayer.pause(cb);
    }

    /**
     * This function resumes or starts playback at the start of the currently active list depending on the current playback state
     */
    public play() {
        if (this.playbackState && this.playbackState.media) {
            this.startPlayback(this.playbackState.media)
        } else {
            this.forward();
        }
    }

    /**
     * This function stops the player and resets the players state
     */
    public stop() {
        this._internalPlayer && this._internalPlayer.stop();
        this.setPlaybackState({ state: PlayerState.None }, undefined, true)
    }

    /**
     * This function skips to the next song if the player is already playing or starts the next track if nothing is playing yet
     */
    public forward(): boolean {
        let playingTrack = this.getTrack();
        if (playingTrack) {
            let nextTrack = this._playbackContentManager.getTrackAfter(playingTrack);
            if (nextTrack) {
                this.startTrack(nextTrack, true)
                return true;
            }
        } else {
            let nextTrack = this._playbackContentManager.getTrackAfter(playingTrack);
            if (nextTrack) {
                this.startTrack(nextTrack, true)
                return true;
            }
        }
        return false;
    }

    /**
     * This function rewinds the current song to the start if playback has occurred for more than 4 seconds or plays the previous song if playback has occurred for less than 4 seconds
     * @param forcePlay - If provided, the track is automatically started after rewinding from a stopped state
     */
    public rewind(forcePlay: boolean = false) {
        if (this._playbackState && this._playbackState.progress && this._playbackState.progress > 4 || forcePlay) {
            this.seek(0);
            if (forcePlay) {
                this.play();
            }
            return;
        }
        let playingTrack = this.getTrack();
        if (playingTrack) {
            let nextTrack = this._playbackContentManager.getTrackBefore(playingTrack);
            if (nextTrack) {
                this.startTrack(nextTrack, this.playbackState && this.playbackState.state !== PlayerState.Paused)
            }
        }
    }

    /**
     * Seeks the track to the specified duration
     * @param to
     */
    public seek(to: number) {
        this._internalPlayer && this._internalPlayer.seek(to);
        this.setPlaybackState(Object.assign({}, this.playbackState, {
            progress: to
        }), this.playbackState && this.playbackState.media, true)
    }

    // Getters

    /**
     * The current instance that manages internal playback state
     */
    public getContentManager(): PlaybackList {
        return this._playbackContentManager;
    }

    /**
     * The current state of playback
     */
    public getPlayingState(): PlayerState {
        if (this.playbackState) {
            return this.playbackState.state
        }
        return PlayerState.None;
    }

    /**
     * The currently active track information
     */
    public getTrack(): FullPlaybackMediaInfo | undefined {
        let state = this.playbackState;
        return state ? state.media : undefined;
    }

    /**
     * Checks if the provided track from the provided list is currently playing
     * @param track 
     * @param playlist 
     */
    public isPlayingTrack(track: Track, playlist: DynamicPlaylist) {
        let playingTrack = this.getTrack();
        return playingTrack && playingTrack.track.id === track.id && playingTrack.playlist.isEqual(playlist)
    }

    /**
     * Checks if the provided album from the provided list is currently playing
     * @param album 
     * @param playlist 
     */
    public isPlayingAlbum(album: Album, playlist: DynamicPlaylist) {
        let playingTrack = this.getTrack();
        return playingTrack && playingTrack.album.id === album.id && playingTrack.playlist.isEqual(playlist)
    }

    /**
     * Checks if the player is currently playing video
     */
    public isPlayingVideo(): boolean {
        return false
    }

    /**
     * Returns the current playback progress
     */
    public getProgress(): number {
        return (this.playbackState ? this.playbackState.progress : 0) || 0;
    }

    /**
     * Returns the duration of the active track
     */
    public getDuration(): number | undefined {
        return this.playbackState && this.playbackState.duration
    }

    // Configuration

    /**
     * Sets the cookied provider to use when streaming songs
     */
    public setCookieProvider(provider: CookieProvider) {
        this._cookieProvider = provider;
    }

    /**
     * Sets the active playback configuration
     */
    public set playbackConfig(newValue: PlaybackConfiguration) {
        this._playbackConfig = newValue;
        this.callPlaybackConfigListeners(newValue)
    };

    /**
     * Returns the active playback configuration
     */
    public get playbackConfig(): PlaybackConfiguration {
        return this._playbackConfig
    };

    private setPlaybackState(newValue: PlaybackProgressState, forMedia: FullPlaybackMediaInfo | undefined, force: boolean = false) {
        if (forMedia && newValue && this._playbackState && this._playbackState.media && this._playbackState.media.track.id === forMedia.track.id) {
            //Do not reset progress ist ths trackid dosent changed!
            if (newValue && newValue.progress == undefined && this._playbackState && this._playbackState.progress !== undefined) {
                newValue.progress = this._playbackState.progress;
            }
            let prevValue = this._playbackState;
            this._playbackState = newValue;
            this.callPlaybackStateListeners(newValue, newValue.state !== prevValue.state)
        } else if (forMedia == undefined && newValue == undefined) {
            this._playbackState = newValue;
            this.callPlaybackStateListeners(newValue, true)
        } else if (forMedia && newValue && !this._playbackState) {
            this._playbackState = newValue;
            this.callPlaybackStateListeners(newValue, true)
        } else if (force) {
            this._playbackState = newValue;
            this.callPlaybackStateListeners(newValue, true)
        }
    };

    /**
     * Returns the current playback state
     */
    public get playbackState(): PlaybackProgressState | undefined {
        return this._playbackState
    };

    /**
     * Sets the auto repeat mode and saves it to local storage
     */
    set autorepeat(newValue: boolean) {
        this.playbackConfig.autorepeat = newValue;
        localStorage[AUTOREPLAY_KEY] = (newValue ? 'true' : 'false')
        // BPMEventHandler.getInstance().callOnEvent({ object: Objects.AUTO_REPEATE });
    }

    /**
     * Returns the current autorepeate mode
     */
    get autorepeat(): boolean {
        return this.playbackConfig.autorepeat;
    }

    /**
    * Sets the shuffle mode and saves it to local storage
    */
    set shuffle(newValue: boolean) {
        this.playbackConfig.shuffle = newValue;
        localStorage[SHUFFLE_KEY] = (newValue ? 'true' : 'false')
        this._playbackContentManager.setPlaybackMode(this.shuffle ? 'shuffle' : 'serial');
        // BPMEventHandler.getInstance().callOnEvent({ object: Objects.SHUFFLE });
    }

    /**
     * Returns the current shuffle mode
     */
    get shuffle(): boolean {
        return this.playbackConfig.shuffle;
    }

    /**
    * Sets the hq mode and saves it to local storage
    */
    set hq(newValue: boolean) {
        let doRestart = this.playbackConfig.hq != newValue
        this.playbackConfig.hq = newValue;
        if (doRestart) {
            this.play()
        }
        localStorage[HQ_KEY] = (newValue ? 'true' : 'false')
    }

    /**
     * Returns the current hq mode
     */
    get hq(): boolean {
        return this.playbackConfig.hq;
    }

    // Listeners

    /**
     * Registers a listener for changes in the current playback configuration
     * @param listener 
     */
    public addPlaybackConfigListener(listener: PlaybackConfigListener): string {
        let id = uuidv4();
        this._playbackConfigListeners[id] = listener;
        return id;
    }

    /**
     * Removes a playback configuration listener
     * @param id 
     */
    public removePlaybackConfigListener(id: string) {
        delete this._playbackConfigListeners[id];
    }

    private callPlaybackConfigListeners(config: PlaybackConfiguration) {
        Object.keys(this._playbackConfigListeners).map((key) => { return this._playbackConfigListeners[key] }).forEach((cb) => {
            cb(config);
        })
    }

    /**
     * Registers a listener for changes in the current playback state
     * @param listener 
     */
    public addPlaybackStateListener(listener: PlaybackStateListener): string {
        let id = uuidv4();
        this._playbackStateListeners[id] = listener;
        return id;
    }

    /**
     * Registers a listener for changes in the current playback progress
     * @param listener 
     */
    public addPlaybackProgressStateListener(listener: PlaybackProgressStateListener): string {
        let id = uuidv4() + '_progress';
        this._playbackStateListeners[id] = listener;
        return id;
    }

    /**
     * Removes a playback state / progress listener
     * @param listener 
     */
    public removePlaybackStateListener(id: string) {
        delete this._playbackStateListeners[id];
    }

    private callPlaybackStateListeners(config: PlaybackState, playbackStateChanged: boolean) {
        Object.keys(this._playbackStateListeners).map((key): [string, PlaybackProgressStateListener] => { return [key, this._playbackStateListeners[key]] }).forEach((data) => {
            let [key, cb] = data;
            if (playbackStateChanged || key.endsWith('_progress')) {
                cb(config)
            }
        })
    }

    /* View Methods */

    private _navigator: Navigator
    getNavigator(): Navigator {
        return this._navigator
    }

    setNavigator(navigator: Navigator) {
        this._navigator = navigator;
    }

    // List Utils

    /**
     * Replaces the current playback queue with a single list
     * @param list
     * @param mode 
     */
    public setList(list: DynamicPlaylist, mode: PlaybackMode) {
        this._playbackContentManager.replaceLists(list);
        this.shuffle = mode === 'shuffle'
    }

    get isPlayerVisible() {
        return this._internalPlayer?.isVisible
    }
}
