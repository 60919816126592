import * as React from 'react'
import { Album } from '../api/models/album';
import { PlayerState } from '../player/types';
import { Track } from '../api/models/track';

export interface PlayerContext {
    album?: Album
    track?: Track
    state: PlayerState
}

const context = React.createContext<PlayerContext>({
    album: undefined,
    state: PlayerState.None
})
export const PlayerContextProvider = context.Provider
export const PlayerContextConsumer = context.Consumer