import DynamicPlaylist from "./DynamicPlaylist";
import { PlaybackMediaInfo } from ".";
import { AddToHistory } from "./types";

export default class PlaybackHistory {

    // Shared Instance
    private static instance: PlaybackHistory;
    public static getInstance(): PlaybackHistory {
        if (PlaybackHistory.instance) {
            return PlaybackHistory.instance;
        }
        PlaybackHistory.instance = new PlaybackHistory();
        return PlaybackHistory.getInstance();
    }

    // Properties

    private _internalList: DynamicPlaylist;

    constructor() {
        this._internalList = new DynamicPlaylist([], {
            history_mode: AddToHistory.LOCAL_ONLY
        }, 'history', false)
    }

    // Getters

    public getTracks(): Array<PlaybackMediaInfo> {
        return <Array<PlaybackMediaInfo>>this._internalList.tracks();
    }

    // Modification

    public addTrack(track: PlaybackMediaInfo) {
        this._internalList.pop(track);
        let tracks = [{
            album: track.album,
            track: track.track,
        }].concat(this._internalList.tracks() as any[])
        this._internalList.replace(tracks.slice(0, 20));
    }

    public appendTracks(tracks: PlaybackMediaInfo[]) {
        this._internalList.appendTracks(tracks)
    }

    public removeTrack(track: PlaybackMediaInfo) {
        this._internalList.pop(track);
    }

    // Listeners

    public addChangeHandler(handler: (list: DynamicPlaylist) => void): string {
        return this._internalList.addChangeHandler(handler)
    }

    public removeChangeHandler(id: string) {
        this._internalList.removeChangeHandler(id);
    }

}