import { AddToHistory, PlaybackMediaInfo, FullPlaybackMediaInfo } from "./types";
import PlaybackManager from "./PlaybackManager";

const uuidv4 = require('uuid/v4');

export type PlaybackMode = 'serial' | 'shuffle';

export interface DynamicPlaylistConfiguration {
    history_mode: AddToHistory
    wantsLoadMore?: () => void;
}

/**
 * This class manages playback of a list of media items
 */
export default class DynamicPlaylist {

    private internalList: Array<PlaybackMediaInfo>;
    private onChangedHandlers: { [key: string]: (list: DynamicPlaylist) => void } = {};
    private config: DynamicPlaylistConfiguration;
    public readonly context?: any;
    private queued: boolean;

    /**
     * Instantiates a new Playlist with an array of media items and a configuration
     * @param media 
     * @param config 
     * @param context Context is used for global identification of linked lists
     * @param queued Defines if this is a queue list or a normal list
     */
    constructor(media: Array<PlaybackMediaInfo>, config: DynamicPlaylistConfiguration = {
        history_mode: AddToHistory.ALL
    }, context: string, queued: boolean) {
        this.context = context;
        this.internalList = media;
        this.queued = queued
        this.config = config;
    }

    // - Data Management

    /**
     * Removes a track from the list
     * @param track 
     */
    public pop(track?: PlaybackMediaInfo) {
        if (track) {
            let index = this.internalList.findIndex((obj1) => {
                return track.track.id === obj1.track.id
            })
            if (index !== -1) {
                this.internalList.splice(index, 1);
            }
        } else {
            this.internalList.pop();
        }
        this.callChangeHandlers(this);
    }

    /**
     * Adds tracks to the list
     * @param media 
     */
    public appendTracks(media: Array<PlaybackMediaInfo>) {
        this.internalList = (<Array<PlaybackMediaInfo>>[]).concat(this.internalList, media);
        this.callChangeHandlers(this);
    }

    /**
     * Replaces all tracks in the list
     * @param media 
     */
    public replace(media: Array<PlaybackMediaInfo>) {
        this.internalList = (<Array<PlaybackMediaInfo>>[]).concat(media);
        this.callChangeHandlers(this);
    }

    /**
     * Adds tracks to the start of the list
     * @param media 
     */
    public prependTracks(media: Array<PlaybackMediaInfo>) {
        this.internalList = (<Array<PlaybackMediaInfo>>[]).concat(media, this.internalList);
        this.callChangeHandlers(this);
    }

    /**
     * Returns the list of tracks
     */
    public tracks(): Array<FullPlaybackMediaInfo> {
        return (<Array<PlaybackMediaInfo>>[]).concat(this.internalList).map((track) => {
            return Object.assign({}, track, { playlist: this })
        })
    }

    // - Getters

    /**
     * Compares two DynamicPlaylists taking into account their context
     * @param toList
     */
    public isEqual(toList: DynamicPlaylist) {
        if (toList === this) { return true }
        if (this.context || toList.context) {
            return this.context === toList.context
        }
        return false
    }

    /**
     * Returns the history mode configuration
     */
    public historyMode(): AddToHistory {
        return this.config.history_mode;
    }

    /**
     * Returns the queue mode configuration
     */
    public queueMode(): boolean {
        return this.queued;
    }

    // - Change Handlers

    /**
     * Registers a handler for changes in the internal track list
     * @param handler 
     */
    public addChangeHandler(handler: (list: DynamicPlaylist) => void): string {
        let id = uuidv4();
        this.onChangedHandlers[id] = handler;
        return id;
    }

    /**
     * Removes a track list change handler
     * @param id 
     */
    public removeChangeHandler(id: string) {
        delete this.onChangedHandlers[id];
    }

    private callChangeHandlers(content: DynamicPlaylist) {
        Object.keys(this.onChangedHandlers).map((key) => { return this.onChangedHandlers[key] }).forEach((cb) => {
            cb(content);
        })
    }
}
