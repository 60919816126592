const local = {
    API: 'http://local.breaksrus.com:3000',
    STRIPE: 'pk_test_gQ0IS6ULnNTPd4zMbFNhFgFq00hjK0dNIH'
};
const dev = {
    API: 'https://api.develop.breaksrus.com',
    STRIPE: 'pk_test_gQ0IS6ULnNTPd4zMbFNhFgFq00hjK0dNIH'
};
const prod = {
    API: 'https://api.breaksrus.com',
    STRIPE: 'pk_live_DA2q0XTIeJSg33mYbvDDmUZg00weQUsBrA'
};

const config = process.env.REACT_APP_STAGE === 'local' ? prod :
    process.env.REACT_APP_STAGE === 'production'
        ? prod
        : dev;
export let Environment = config;
(global as any).Environment = config