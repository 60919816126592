import * as React from "react";
import { PaymentRequestButtonElement, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { StripeElements, Stripe, PaymentRequest, PaymentMethod } from "@stripe/stripe-js";
import { ClubsetsButton } from "../Components/ClubsetsButton";
import { translations } from "../translations";
import { Colors } from "../assets";
import { UserAPI, PromoCode } from "../api/endpoints/user";
import { errorToString } from "../api/errors";
import { ClubsetsTextInput } from "../Components/ClubsetsTextInput";
import * as toastr from 'toastr'
import { UserContextType } from "../context/user";
type CheckoutProps = { elements?: StripeElements | null, stripe: Stripe | null, onSubscribed: () => void }
class CheckoutForm extends React.Component<CheckoutProps, {
    paymentRequest?: PaymentRequest,
    loading: boolean,
    currentCode?: string,
    lastValidPromoCode?: PromoCode,
    applePayLoading: boolean
}> {

    static contextType = UserContextType
    context!: React.ContextType<typeof UserContextType>

    button: ClubsetsButton | null;

    constructor(p: any) {
        super(p);
        this.state = {
            loading: false,
            applePayLoading: false
        }
    }

    componentDidMount() {
        this.loadPaymentRequest()
    }

    componentDidUpdate(prevProps: CheckoutProps) {
        if (prevProps.stripe != this.props.stripe) {
            this.loadPaymentRequest()
        }
    }

    loadPaymentRequest() {
        if (this.props.stripe) {
            const pr = this.props.stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                displayItems: [
                    {
                        label: 'BREAKSRUS Membership',
                        amount: 999,
                    }
                ],
                total: {
                    label: 'BREAKSRUS',
                    amount: 999,
                },
                requestPayerName: true,
                requestPayerEmail: true
            });
            pr.on('paymentmethod', (ev) => {
                this.setState({ applePayLoading: true })
                this.registerPaymentMethodAndSubscribe(ev.paymentMethod).then(() => {
                    ev.complete('success')
                }).catch(err => {
                    ev.complete('fail')
                })
            })
            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result) {
                    this.setState({ paymentRequest: pr });

                }
            });
        }
    }

    private async registerPaymentMethodAndSubscribe(method: PaymentMethod) {
        const { onSubscribed } = this.props;
        return UserAPI.setCreditCard(method!.id).then(() => {
            return UserAPI.subscribe(this.state.lastValidPromoCode?.id).then(() => {
                onSubscribed()
            })
        }).catch(err => {
            this.button!.loading(false)
            this.button!.showTooltip(errorToString(err).join('\n'))
            this.setState({ applePayLoading: false })
            throw err
        })
    }

    private async handleSubmit() {
        // Block native form submission.

        const { stripe, elements } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        this.button!.loading(true)
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement!,
        });

        if (error) {
            this.button!.loading(false)
            this.button!.showTooltip(error.message!)
        } else {
            this.registerPaymentMethodAndSubscribe(paymentMethod!)
        }
    };

    checkPromoCode(code: string) {
        this.setState({ loading: true, currentCode: code })
        setTimeout(() => {
            if (this.state.currentCode == code) {
                if (code.trim() == '') {
                    this.setState({ loading: false })
                    return
                }
                this.button?.loading(true)
                UserAPI.checkPromoCode(code).then((lastValidPromoCode) => {
                    if (this.state.currentCode == code) {
                        this.setState({ lastValidPromoCode, loading: false })
                        this.button?.loading(false)
                    }
                }).catch(() => {
                    if (this.state.currentCode == code) {
                        this.setState({ loading: false })
                        this.button?.loading(false)
                        toastr.error(`Promo code '${code}' is not valid`)
                    }
                })
            }
        }, 500)

    }

    private renderPromocode() {
        if (!this.state.lastValidPromoCode) {
            return null
        }
        return <span className='promo-code'>
            Promo code '{this.state.lastValidPromoCode.id}' applied! You get {this.state.lastValidPromoCode.amount_off ? ('$' + (this.state.lastValidPromoCode.amount_off / 100).toFixed(2)) : (this.state.lastValidPromoCode.percent_off + '%')} off for{this.state.lastValidPromoCode.duration == 'once' ? ' the first month' : 'ever'}
        </span>
    }

    render() {
        const { stripe } = this.props;
        const { paymentRequest, loading, applePayLoading } = this.state
        return (
            <div className='checkout'>
                <div className='info-container'>
                    <span className="info">{translations.PremiumInfo}</span>
                    <p><b>{translations.TrialDescription}</b></p>
                </div>
                <ClubsetsTextInput caption={'Promo Code'} onChange={(val) => {
                    this.checkPromoCode(val)
                }} />
                {this.renderPromocode()}
                <div className="input-container">
                    <span>Credit Card</span>
                    <div className='input' style={{ paddingTop: 15.5 }}>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        padding: 10,
                                        fontSize: '14px',
                                        color: this.context.theme == 'dark' ? Colors.WHITE : Colors.GREY,
                                        '::placeholder': {
                                            color: Colors.LIGHT_GREY,
                                        },
                                    },
                                    invalid: {
                                        color: '#DA291C',
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
                <div className="button-container">
                    <ClubsetsButton ref={(ref) => this.button = ref} text={translations.Subscribe} disabled={!stripe || loading || applePayLoading} onPress={() => {
                        this.handleSubmit()
                    }} />
                    {paymentRequest && <>
                        <span className='or-payment'>OR</span><div>
                            <PaymentRequestButtonElement options={{
                                paymentRequest,
                                style: { paymentRequestButton: { theme: 'light', height: '53px' } }
                            }} />
                        </div>
                    </>}
                </div>

            </div>
        );
    }
}

export const InjectedCheckoutForm = (props: { onSubscribed: () => void }) => {
    const stripe = useStripe();
    const elements = useElements()
    return (
        <CheckoutForm
            elements={elements}
            stripe={stripe}
            onSubscribed={props.onSubscribed} />
    );
};