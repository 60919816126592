import { BaseModel } from "./base"
import { User } from "./user";

export class Device extends BaseModel {


    ISHELPERMODEL: string;

    MODELNAME: string;


    app_version: string;


    build_version: string;


    device_token?: string;


    token_provider?: string;


    device_uuid: string;


    device_data_os?: string;


    device_data_os_version?: string;


    device_data_device_type?: string;


    device_data_device_name?: string;


    device_data_device_category?: string;


    debug: boolean;


    language: string;


    valid: boolean;


    session_token: string | undefined;


    user?: User | any;


    user_id: string;
}
