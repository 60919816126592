import * as React from 'react'
import { Colors } from '../assets';

export class Box extends React.Component<{
    bgColor?: string
    header?: string
    className?: string
}> {

    render() {
        const { bgColor, header, className } = this.props
        return(
            <div className={`box ${className || ''}`} style={{backgroundColor: bgColor || Colors.LIGHT_BLACK}}>
                {header && <div className="header">{header}</div>}
                {this.props.children}
            </div>
        )
    }
}