import * as React from 'react'
import { Colors } from '../assets';

export class ClubsetsHeadline extends React.Component<{ 
    title: string,
    classname?: string
}> {

    render() {

        return (
            <div style={{display: "flex", flex: 1, justifyContent: "center"}} className={this.props.classname}>
                <span style={{ fontSize: "1.875rem", fontWeight: 700, color: Colors.WHITE }}>{this.props.title}</span>
            </div>

        )
    }
}