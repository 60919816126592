import { Options, request } from "./native_request";
import MapUtils from "./jsonparser";
import { APIErrorType, InvalidSubscriptionError } from "../errors";
import { User } from "../models/user";

/**
 * The currenly used api url
 * @type {string}
 */
let apiURL = 'https://api.breaksrus.com';
if (typeof global !== 'undefined') {
    if (typeof (global as any).Environment !== 'undefined' && typeof (global as any).Environment.API === 'string') {
        apiURL = (global as any).Environment.API
    }
}
/**
 * The currenly used user agent
 * @type {string}
 */
let userAgent = 'breaksrus-web';

/**
 * The global handler for unauthorized handlers
 */
let unauthorizedHandler: ((error: APIError) => void) | undefined = undefined;

let userUpdatedHandler: ((user: User) => void) | undefined = undefined;


/**
 * The global handler for subscription errors
 */
let invalidSubscriptionHandler: ((error: InvalidSubscriptionError) => void) | undefined = undefined;

/**
 * This encapsulates the current session information
 */
export class Session {
    private static cachedSession: { session_token: string };
    /**
     * This sets the global unauthorized handler
     */
    static setUnauthorizedHandler(handler: ((error: APIError) => void) | undefined) {
        unauthorizedHandler = handler;
    }

    /**
     * This returns the global unauthorized handler
     */
    static getUnauthorizedHandler(): ((error: APIError) => void) | undefined {
        return unauthorizedHandler;
    }

    static setUserUpdatedHandler(handler: ((user: User) => void) | undefined) {
        userUpdatedHandler = handler;
    }

    static getUserUpdatedHandler() {
        return userUpdatedHandler || ((user) => { })
    }

    /**
     * This sets the global subscription error handler
     */
    static setSubscriptionHandler(handler: ((error: InvalidSubscriptionError) => void) | undefined) {
        invalidSubscriptionHandler = handler;
    }

    /**
     * This returns the subscription error handler
     */
    static getSubscriptionHandler(): ((error: InvalidSubscriptionError) => void) | undefined {
        return invalidSubscriptionHandler;
    }

    /**
     * This sets the api url to use
     * @param {string} url
     */
    static setAPIURL(url: string) {
        apiURL = url;
    }

    /**
     * This returns the currently used api url
     * @returns {string}
     */
    static getAPIURL(): string {
        return apiURL;
    }

    /**
     * This sets the user agent to use
     * @param {string} url
     */
    static setUserAgent(agent: string) {
        userAgent = agent;
    }

    /**
     * This returns the currently used user agent
     * @returns {string}
     */
    static getUserAgent(): string {
        return userAgent;
    }

    /**
     * This retreives the current session data
     * @returns {string | undefined}
     */
    static getSession(): string | undefined {
        return this.cachedSession ? this.cachedSession.session_token : undefined;
    }

    /**
     * This sets the current session data
     * @param {any | string | undefined} session
     */
    static setSession(session: any | string | undefined) {
        this.cachedSession = typeof session === 'object' ? session : { 'session_token': session }
    }
}


export type RequestType<T> = Options;
export type APIError = {
    type: APIErrorType
    status: number
    message: string
    underlying?: Error
    missing_keys?: string[]
    invalid_keys?: string[]
    existing_keys?: string[]
}

export enum HTTPMethod {
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE",
    POST = "POST",
}

export enum APIVersion {
    v1_0 = "v1"
}

export enum GenericAPIVersion {
    v3_0 = "v3"
}

export type APIResponse<T> = {
    data?: T | T[], meta?: { [key: string]: any }, pagination?: {
        page: number;
        total: number;
        limited: number;
        skipped: number;
    }, originalResponse: Response
}

export interface FCRequest<T> {

    path: string;
    method: HTTPMethod;
    apiVersion: APIVersion;
    queryParameters?: { [key: string]: string };
    bodyParameters?: { [key: string]: any };

    serialize(): RequestType<T>
}

export class BasicRequest<T> implements FCRequest<T> {

    path: string;
    method: HTTPMethod;
    apiVersion: APIVersion;
    queryParameters?: { [key: string]: string };
    bodyParameters?: { [key: string]: any };
    refresh_token?: string;
    preventInvalidSubscriptionHandler = false

    overrideAPIUrl?: string

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }) {
        this.path = path;
        this.method = method;
        this.apiVersion = apiVersion;
        this.queryParameters = queryParameters;
        this.bodyParameters = bodyParameters;
    }

    serialize(): RequestType<T> {
        let apiRequest: Options = {
            url: (this.overrideAPIUrl || apiURL) + '/' + this.apiVersion + this.path,
            json: true,
            method: this.method,
            headers: {},
            user_agent: userAgent
        };
        if (this.refresh_token) {
            apiRequest.headers = Object.assign({}, apiRequest.headers, { 'Authorization': 'Bearer ' + this.refresh_token })
        }
        if (this.queryParameters) {
            apiRequest.qs = this.queryParameters
        }
        if (this.bodyParameters) {
            apiRequest.body = this.bodyParameters;
        }
        return apiRequest
    }

    public send(returnType?: { new(): T }): Promise<APIResponse<any>> {
        let promise = new Promise((resolve, reject: (reason?: any) => void) => {
            request(this.serialize(), (error?: Error, response?: Response, body?: any) => {
                if (error) {
                    reject(error);
                } else if (response && response.status >= 200 && response.status <= 299) {
                    if (body && body.data) {
                        if (!returnType) {
                            return resolve({
                                data: body.data,
                                pagination: body.pagination,
                                originalResponse: response
                            })
                        }
                        if (Array.isArray(body.data)) {
                            resolve({
                                data: body.data.map((body: any): T | undefined => {
                                    try {
                                        return this.parseReponse(returnType, body)
                                    } catch (error) {
                                        console.log(error);
                                        return undefined
                                    }
                                }).filter(Boolean),
                                pagination: body.pagination,
                                originalResponse: response
                            });
                        } else {
                            try {
                                resolve({
                                    data: this.parseReponse(returnType, body.data),
                                    pagination: body.pagination,
                                    originalResponse: response
                                });
                            } catch (error) {
                                reject({
                                    type: APIErrorType.UnknownError,
                                    status: 500,
                                    underlyingError: error,
                                    message: error.message || 'The response could not be parsed'
                                })
                            }
                        }
                    } else if (body && body.error) {
                        if ([APIErrorType.SessionNotFoundError, APIErrorType.MissingAuthorizationError].includes(body.error.type) && unauthorizedHandler) {
                            try {
                                unauthorizedHandler(body.error);
                            } catch (e) {
                                console.log('unauthorizedHandler threw: ' + e.toString());
                            }
                        } else if (!this.preventInvalidSubscriptionHandler && [APIErrorType.InvalidSubscriptionError].includes(body.error.type) && invalidSubscriptionHandler) {
                            try {
                                invalidSubscriptionHandler(body.error);
                            } catch (e) {
                                console.log('invalidsubscriptionhandler threw: ' + e.toString());
                            }
                        }
                        reject(body.error)
                    } else {
                        reject({
                            type: APIErrorType.UnknownError,
                            status: 500,
                            message: 'The response could not be parsed'
                        })
                    }
                } else {
                    if (body && body.error) {
                        if ([APIErrorType.SessionNotFoundError, APIErrorType.MissingAuthorizationError].includes(body.error.type) && unauthorizedHandler) {
                            try {
                                unauthorizedHandler(body.error);
                            } catch (e) {
                                console.log('unauthorizedHandler threw: ' + e.toString());
                            }
                        } else if (!this.preventInvalidSubscriptionHandler && [APIErrorType.InvalidSubscriptionError].includes(body.error.type) && invalidSubscriptionHandler) {
                            try {
                                invalidSubscriptionHandler(body.error);
                            } catch (e) {
                                console.log('invalidsubscriptionhandler threw: ' + e.toString());
                            }
                        }
                        reject(body.error)
                    } else {
                        reject({
                            type: APIErrorType.UnknownError,
                            status: 500,
                            message: 'The response could not be parsed'
                        })
                    }
                }
            })
        });
        return promise.catch((error) => {
            if (error.type && error.message && error.status) {
                throw error
            } else {
                const error: any = new Error('message');
                error.type = APIErrorType.NetworkError;
                error.status = 999;
                error.message = 'An unknown error occurred / ' + this.path + ' / ' + this.method;
                error.underlying = error;
                throw error
            }
        }) as Promise<APIResponse<T>>;
    }

    private parseReponse(clazz: { new(): T }, response: any): T | undefined {
        return MapUtils.deserialize(clazz, response)
    }

}

export class AuthenticatedRequest<T> extends BasicRequest<T> {

    private authToken?: string;

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }) {
        super(path, method, apiVersion, queryParameters, bodyParameters);
        this.authToken = Session.getSession();
    }

    serialize(): RequestType<T> {
        let options = super.serialize();
        if (this.authToken && !this.refresh_token) {
            options.headers = Object.assign({}, options.headers, { 'Authorization': 'Bearer ' + this.authToken });
        }
        return options
    }
}

export class AuthenticatedFileRequest<T> extends AuthenticatedRequest<T> {

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: string },
        bodyParameters?: { [key: string]: any }) {
        if (bodyParameters) {
            let body = new FormData();
            let data = bodyParameters;
            for (let key of Object.keys(data)) {
                body.append(key, data[key], 'image');
            }
            bodyParameters = body;
        }
        super(path, method, apiVersion, queryParameters, bodyParameters);
    }

    serialize(): RequestType<T> {
        let options = super.serialize();
        options.json = false;
        return options
    }
}
