import { BaseModel } from "./base"
import { Device } from "./device";
import { Category } from "./category";

export enum Permission {
    INGESTION = 'ingestion',
    META = 'meta',
    SOUNDPACKAGE = 'soundpackage',
    ADMIN = 'all',
    USER = 'user'
}

export class User extends BaseModel {

    email: string;
    full_name: string;
    theme: 'dark' | 'light'
    chord_setting: 'minor' | 'major'

    hidden_categories: Category[]

    devices?: Device[];
    profile_image_url?: string;
    profile_image_thumbnail_url?: string;

    is_admin?: boolean;
    admin_features?: { features: string[] }
}