import * as React from 'react'
import { Assets } from '../assets';

export interface ClubsetsLoaderProps {
    style?: React.CSSProperties, 
    size?: number, 
    white?: boolean 
}

export class ClubsetsLoader extends React.Component<ClubsetsLoaderProps, {}> {

    render() {
        return (
            <div style={{ width: '100%', ...this.props.style }}>
                <img style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} src={Assets.LOADER} width={this.props.size || 60} alt={'Loader'} />
            </div>
        )
    }
}