import { APIError } from "../api/requests/request"
import { APIErrorType, errorToString } from "../api/errors"
import * as H from 'history'

import * as toastr from 'toastr'
export function downloadMessageHandler(err: APIError, config: { target: HTMLElement, desiredPosition: string } | undefined, history: H.History) {
    if (err.type == APIErrorType.InvalidSubscriptionError) {
        let text = 'Get Full Access. Start your Free 14 Day Trial Now.'
        if (config) {
            config.target.setAttribute('data-balloon', text)
            config.target.setAttribute('data-balloon-visible', '')
            config.target.setAttribute('data-balloon-pos', config.desiredPosition)
        }
        toastr.error(text, undefined, {
            onclick: () => {
                history.push('/subscribe')
            }
        })
    } else if (err.type == APIErrorType.DownloadLimitReachedError) {
        let text = 'You reached the download limit for this track.'
        if (config) {
            config.target.setAttribute('data-balloon', text)
            config.target.setAttribute('data-balloon-visible', '')
            config.target.setAttribute('data-balloon-pos', config.desiredPosition)
        }
        toastr.error(text)
    } else {
        errorToString(err).forEach(str => {
            toastr.error(str)
        })
    }
}