import * as React from "react";
import { Collapse } from "reactstrap";
import { Assets } from "../assets";


export const FAQ = () => {
    return (
        <div className="faq-container">
            <div className="faq-inner">
                <div className="faq-headline">
                    <span>{'FAQs'}</span>
                </div>
                {FAQs.map((item) => {
                    return (
                        <FAQItem key={item.answer}
                            question={item.question}
                            answer={item.answer}
                        />
                    )
                })}
                <div className="faq-item">
                    <div className="question">
                        <span>You Still Have Questions?</span>
                    </div>
                    <div className="answer">
                        <span>
                            We are happy to help. Email us at <a href="mailto:support@breaksrus.com">support@breaksrus.com</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export class FAQItem extends React.Component<{
    question: string
    answer: string
}, {
    isOpen: boolean
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    private openToggle() {
        if (this.state.isOpen) {
            this.setState({ isOpen: false })
        } else {
            this.setState({ isOpen: true })
        }
    }

    render() {

        return (
            <div className="faq-item">
                <div className="question" onClick={() => { this.openToggle() }}>
                    <span>{this.props.question}</span>
                    <img className="icon" src={this.state.isOpen ? Assets.ARROW_UP : Assets.ARROW_DOWN} alt='arrow' />
                </div>
                <Collapse isOpen={this.state.isOpen}>
                    <div className="answer">
                        <span>{this.props.answer}</span>
                    </div>
                </Collapse>
            </div>
        )
    }
}

const FAQs = [
    {
        question: "How Much Does It Cost?",
        answer: `BREAKSRUS is offering a special price of $9.99/ per month to everyone who signs up before December 01, 2020. BREAKSRUS will increase the price on December 01, 2020.`,
    },
    {
        question: "How Do I Cancel My Account?",
        answer:
            "While logged into your account, visit the billing page located in the dropdown menu in the top right corner.",
    },
    {
        question: "What Genres Do You Provide?",
        answer: `Our objective is to provide you an arsenal of handpicked DJ edits that will
        take your sets to the next level. We provide the latest in (Hip Hop, Reggaeton, Latin House, House, Dembow, Moombathon, and Trap)`,
    },
    {
        question: "What Type of Format Do You Provide?",
        answer: "BREAKSRUS only offers MP3 format. We do not offer video. All files are tagged and ready to upload and play on your favorite software.",
    },
    {
        question: "How Can I Reactivate My Previously Canceled Account?",
        answer: "Log into your existing account and simply visit the billing page located in the dropdown menu on the top right corner.",
    },
    {
        question: "What Date Will I Be Charged?",
        answer: "All recurring payments are billed 1 month from the day you signed up.",
    },
    {
        question: "What Payment Methods Does BREAKSRUS Accept?",
        answer: "BREAKSRUS only accepts credit card or apple pay transactions.",
    }
];