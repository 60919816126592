export enum APIErrorType {
    InvalidParameters = "InvalidParametersError",
    InvalidSubscriptionError = "InvalidSubscriptionError",
    PaymentInfoMissingError = "PaymentInfoMissingError",
    AlreadyExists = "ResourceAlreadyExists",
    InvalidGoogleToken = "InvalidGoogleToken",
    InvalidFacebookToken = "InvalidFacebookToken",
    InvalidLogin = "InvalidLoginError",
    ResourceNotFound = "ResourceNotFound",
    NotAccessibleError = "NotAccessibleError",
    UnknownError = "UnknownError",
    NetworkError = "NetworkError",
    MissingAuthorizationError = "MissingAuthorizationError",
    SessionNotFoundError = "SessionNotFoundError",
    DownloadLimitReachedError = "DownloadLimitReachedError"
}

export enum SubscriptionInfo {
    'None' = 0,
    'Standard' = 1
}

export interface InvalidSubscriptionError {
    type: string;
    status: number;
    current_supscription: SubscriptionInfo;
    required_subscription: SubscriptionInfo;
    message: string;
}

export function errorToString(err: { type?: string, message: string }, defaultMessage = 'An unhandled error occurred. Please try again later.'): Array<string> {
    if (err && err.type === 'InvalidParametersError') {
        if ((err as any).missing_keys && (err as any).missing_keys.length) {
            return (err as any).missing_keys.map((k: string) => k + ' is missing')
        }
        return Object.keys((err as any).invalid_keys).map((key) => {
            return key + ': ' + (err as any).invalid_keys[key]
        })
    } else if ((err && err.type === 'ResourceAlreadyExists') || (err && err.message)) {
        return [err.message]
    } else {
        return [defaultMessage]
    }
}