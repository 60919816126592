import * as React from 'react'
import { Assets } from '../assets';
import { Category } from '../api/models/category';
import { ClubsetsLoader } from './ClubsetsLoader';
import { MediaAPI } from '../api/endpoints/media';
import { UserContextType } from '../context/user';

export class SideMenu extends React.Component<{
    onNavItemPressed: (item: string) => void
    onCategoryPressed: (category: Category) => void,
    selectedItem?: string
}, { categories?: Category[], showMenu: boolean }> {

    static contextType = UserContextType
    context!: React.ContextType<typeof UserContextType>

    constructor(props: any) {
        super(props);
        this.state = {
            showMenu: false
        }
    }

    componentDidMount() {
        MediaAPI.getCategories().then(categories => {
            this.setState({ categories })
        })
    }

    render() {

        return (
            <div className="menu-container">
                <div className="nav-container">
                    {this.renderNavItem('home', [Assets.ICON_HOME, Assets.ICON_HOME_ORANGE], 'Home', this.props.selectedItem)}
                    {this.renderNavItem('liked-songs', [Assets.ICON_LIKED_SONGS, Assets.ICON_LIKED_SONGS_ORANGE], 'Liked Songs', this.props.selectedItem)}
                    {this.renderNavItem('most-liked-songs', [Assets.ICON_MOST_LIKED_SONGS, Assets.ICON_MOST_LIKED_SONGS_ORANGE], 'Most Liked Songs', this.props.selectedItem)}
                    {this.renderNavItem('top-downloads', [Assets.ICON_TOP_DOWNLOADS, Assets.ICON_TOP_DOWNLOADS_ORANGE], 'Top Downloads', this.props.selectedItem)}
                </div>
                <div className="categories-container">
                    <div className="categories-headline">{'CATEGORIES'}</div>
                    {!this.state.categories && <ClubsetsLoader />}
                    {this.state.categories && this.state.categories.map((item) => {
                        const hidden = this.context?.hidden_categories.find(c => c.id == item.id) != undefined
                        return (
                            <div key={item.id} className={`category-item ${(item.name == this.props.selectedItem) ? 'active' : ''} ${hidden ? 'disabled' : ''}`} onClick={() => this.props.onCategoryPressed(item)}>{item.name}</div>
                        )
                    })}
                </div>
            </div>
        )
    }

    private renderNavItem(id: string, icon: [string, string], name: string, selectedId?: string) {
        return (
            <div className={`nav-item ${(id == selectedId) ? 'active' : ''}`} key={id} onClick={() => this.props.onNavItemPressed(id)}>
                <span className={`nav-text ${(id == selectedId) ? 'active' : ''}`}>{name}</span>
            </div>
        )
    }
}
