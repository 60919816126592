import { AuthenticatedRequest, HTTPMethod, Session, APIVersion, AuthenticatedFileRequest, BasicRequest } from "../requests/request";
import { User } from "../models/user";
export interface Card {
    id: string
    brand: string
    last4: string
    exp: string
}

export interface Invoice {
    id: string
    created: string
    invoice_pdf: string
    total: number
}

export interface Subscription {
    id: string
    subscription_id: string;
    plan_id: string;
    user_id?: string;
    amount_paid: number;
    initial_start_date: Date;
    start_date: Date;
    renewal_date: Date;
    expire_date: Date;
    canceled_date?: Date;
    type?: 'initial' | 'renewal' | 'renewal_failed' | 'upgrade'
}

export interface PromoCode {
    id: string,
    amount_off: number | null,
    duration: 'once' | 'forver',
    name: string,
    percent_off: number | null,
}
export class UserAPI {

    public static resetPassword(email: string): Promise<void> {
        let apiRequest = new BasicRequest('/forgotpassword', HTTPMethod.POST, APIVersion.v1_0, undefined, { email });

        return apiRequest.send().then((response) => {
        });
    }

    public static resetMyPassword(): Promise<void> {
        let apiRequest = new AuthenticatedRequest('/forgotpassword', HTTPMethod.POST);

        return apiRequest.send().then((response) => {
        });
    }

    public static getMe(): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/users/me', HTTPMethod.GET);
        return apiRequest.send(User).then((response) => {
            Session.getUserUpdatedHandler()(response.data)
            return response.data as User;
        });
    }

    public static checkPromoCode(code: string): Promise<PromoCode> {
        let apiRequest = new AuthenticatedRequest<PromoCode>('/coupon/' + code, HTTPMethod.GET);

        return apiRequest.send().then((response) => {
            return response.data as PromoCode;
        });
    }

    public static getSubscription(): Promise<Subscription> {
        let apiRequest = new AuthenticatedRequest<Subscription>('/users/me/subscription', HTTPMethod.GET);

        return apiRequest.send().then((response) => {
            return response.data as Subscription;
        });
    }

    public static getCreditCard(): Promise<Card> {
        let apiRequest = new AuthenticatedRequest<Card>('/credit-card/default', HTTPMethod.GET);

        return apiRequest.send().then((response) => {
            return response.data as Card;
        });
    }

    public static getInvoices(): Promise<Invoice[]> {
        let apiRequest = new AuthenticatedRequest<Invoice>('/invoices', HTTPMethod.GET);

        return apiRequest.send().then((response) => {
            return response.data as Invoice[];
        });
    }

    public static cancelSubscription(): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/subscription', HTTPMethod.DELETE);

        return apiRequest.send(User).then((response) => {
            return response.data as User;
        });
    }

    public static setCreditCard(token: string): Promise<Card> {
        let apiRequest = new AuthenticatedRequest<Card>('/credit-card', HTTPMethod.POST, APIVersion.v1_0, undefined, { token });

        return apiRequest.send().then((response) => {
            return response.data as Card;
        });
    }

    public static subscribe(coupon?: string): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/subscribe', HTTPMethod.POST, APIVersion.v1_0, undefined, { coupon });

        return apiRequest.send(User).then((response) => {
            Session.getUserUpdatedHandler()(response.data)
            return response.data as User;
        });
    }

    public static uploadProfilePicture(image: any): Promise<User> {
        let apiRequest = new AuthenticatedFileRequest<User>('/users/me/picture', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image
        });
        return apiRequest.send(User).then((response) => {
            Session.getUserUpdatedHandler()(response.data)
            return response.data;
        });
    }

    public static updateMe({ full_name, email, chord_setting, theme }: { full_name?: string; email?: string; theme?: 'light' | 'dark'; chord_setting?: 'minor' | 'major' }): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/users/me', HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            full_name, email, chord_setting, theme
        });
        return apiRequest.send(User).then((response) => {
            Session.getUserUpdatedHandler()(response.data)
            return response.data;
        });
    }


    public static setCategoriesHidden(categories: string[]): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/users/me/hiddenCategories', HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            categories
        });
        return apiRequest.send(User).then((response) => {
            Session.getUserUpdatedHandler()(response.data)
            return response.data;
        });
    }

    public static logout(): Promise<void> {
        let apiRequest = new AuthenticatedRequest<void>('/logout', HTTPMethod.POST)
        return apiRequest.send().then((response) => {
            return response.data;
        });
    }
}
