import React from "react";
import { Assets } from '../assets/index'

const PrivacyPolicy = () => {
    return (
        <div className="PrivacyPolicy">
            <div className="content-container">
                <img src={Assets.LOGO_DAY} alt="" />
                <h5>Privacy Policy</h5>
                <p>
                    Protecting your private information is our priority. This
                    Statement of Privacy applies to
                    <a href="www.breaksrus.com"> www.breaksrus.com</a> and Club
                    Sets LLC and governs data collection and usage. For the
                    purposes of this Privacy Policy, unless otherwise noted, all
                    references to Club Sets LLC include{" "}
                    <a href="www.breaksrus.com"> www.breaksrus.com</a> and Club
                    Sets. The Club Sets website is a digital content
                    subscription site. By using the Club Sets website, you
                    consent to the data practices described in this statement.
                </p>
                <p>Collection of your Personal Information</p>
                <p>
                    In order to better provide you with products and services
                    offered on our Site, Club Sets may collect personally
                    identifiable information, such as your:
                </p>
                <ul>
                    <li>- &nbsp; &nbsp; First and Last Name</li>
                    <li>- &nbsp; &nbsp; Mailing Address</li>
                    <li>- &nbsp; &nbsp; E-mail Address</li>
                    <li>- &nbsp; &nbsp; Phone Number</li>
                </ul>
                <p>
                    If you purchase Club Sets’ products and services, we collect
                    billing and credit card information. This information is
                    used to complete the purchase transaction.
                </p>
                <p>
                    Club Sets may also collect anonymous demographic
                    information, which is not unique to you, such as your:
                </p>
                <ul>
                    <li>- &nbsp; &nbsp; Age</li>
                    <li>- &nbsp; &nbsp; Gender</li>
                </ul>
                <p>
                    We do not collect personal information about you unless you
                    voluntarily provide it to us. However, you may be required
                    to provide certain personal information to us when you elect
                    to use certain products or services available on the Site.
                    These may include: (a) registering for an account on our
                    Site; (b) entering a sweepstakes or contest sponsored by us
                    or one of our partners; (c) signing up for special offers
                    from selected third parties; (d) sending us an email
                    message; (e) submitting your credit card or other payment
                    information when ordering and purchasing products and
                    services on our Site. To wit, we will use your information
                    for, but not limited to, communicating with you in relation
                    to services and/or products you have requested from us. We
                    also may gather additional personal or non-personal
                    information in the future.
                </p>
                <h5>Use of your Personal Information</h5>
                <p>
                    Club Sets collects and uses your personal information to
                    operate its website and deliver the services you have
                    requested.
                </p>
                <p>
                    Club Sets may also use your personally identifiable
                    information to inform you of other products or services
                    available from Clubs Sets and its affiliates.
                </p>
                <h5>Sharing Information with Third Parties</h5>
                <p>
                    Club Sets does not sell, rent or lease its customer lists to
                    third parties.
                </p>
                <p>
                    Club Sets may share data with trusted partners to help
                    perform statistical analysis, send you email or postal mail,
                    provide customer support, or arrange for deliveries. All
                    such third parties are prohibited from using your personal
                    information except to provide these services to Club Sets,
                    and they are required to maintain the confidentiality of
                    your information.
                </p>
                <p>
                    Club Sets may disclose your personal information, without
                    notice, if required to do so by law or in the good faith
                    belief that such action is necessary to: (a) conform to the
                    edicts of the law or comply with legal process served on
                    Club Sets or the site; (b) protect and defend the rights or
                    property of Club Sets; and/or (c) act under exigent
                    circumstances to protect the personal safety of users of
                    Club Sets, or the public.
                </p>
                <h5>Tracking User Behavior</h5>
                <p>
                    Club Sets may keep track of the websites and pages our users
                    visit within Club Sets, in order determine what Club Sets
                    services are the most popular. This data is used to deliver
                    customized content and advertising within Club Sets to
                    customers whose behavior indicates that they are interested
                    in a particular subject area.
                </p>
                <h5>Automatically Collected Information</h5>
                <p>
                    Information about your computer hardware and software may be
                    automatically collected by Club Sets. This information can
                    include your IP address, browser type, domain names, access
                    times and referring website addresses. This information is
                    used for the operation of the service, to maintain quality
                    of the service, and to provide general statistics regarding
                    use of the Club Sets website.
                </p>
                <h5>Use of Cookies</h5>
                <p>
                    The Club Sets website may use “cookies” to help you
                    personalize your online experience. A cookies is a text file
                    that is placed on your hard disk by a web page server.
                    Cookies cannot be used to run programs or deliver viruses to
                    your computer. Cookies are uniquely assed to you, and can
                    only be read by a web server in the domain that issued the
                    cookie to you.
                </p>
                <p>
                    One of the primary reasons cookies is to provide a
                    convenience feature to save you time. The purpose of a
                    cookie is to tell the Web server that you have returned to a
                    specific page. For example, if you personalize your Club
                    Sets page or register with Club Sets, a cookie helps Club
                    Sets recall your specific information on subsequent visits.
                    This simplifies the process of recording your personal
                    information, such as billing addresses, shipping addresses,
                    and so on. When you return to the same Club Sets website,
                    the information you previously provided can be retrieved, so
                    you can easily use the Club Sets features that you
                    customized.
                </p>
                <p>
                    You have the ability to accept or decline cookies. Most web
                    browsers accept cookies, but you can usually modify your
                    browser setting to decline cookies if you prefer. If you
                    choose to decline cookies, you may not be able to fully
                    experience the interactive features of the Club Sets
                    services or websites you visit.
                </p>
                <h5>Security of your Personal Information</h5>
                <p>
                    Club Sets secures your personal information from
                    unauthorized access, use, or disclosure. Club Sets uses the
                    following methods for this purpose:
                </p>
                <ul>
                    <li>- &nbsp; &nbsp; SSL Protocol</li>
                </ul>
                <p>
                    When personal information (such as a credit card number) is
                    transmitted to other websites, it is protected through the
                    use of encryption, such as the Secure Sockets Layer (SSL)
                    protocol.
                </p>
                <h5>Children Under Thirteen</h5>
                <p>
                    Club Sets does not knowingly collect personally identifiable
                    information from children under the age of thirteen. If you
                    are under the age of thirteen, you must ask your parent or
                    guardian for permission to use this website.
                </p>
                <h5>Email Communications</h5>
                <p>
                    From time to time, Club Sets may contact you via email for
                    the purpose of providing announcements, promotional offers,
                    alerts, confirmations, surveys, and/or other general
                    communication. In order to improve our Services, we may
                    receive a notification when you open an email from Club Sets
                    or click a link therein.
                </p>
                <p>
                    If you would like to stop receiving marketing or promotional
                    communications via email from Club Sets, you may opt out of
                    such communications contacting{" "}
                    <a href="mailto:support@breaksrus.com">
                        support@breaksrus.com
                    </a>
                </p>
                <h5>Changes to this Statement</h5>
                <p>
                    Club Sets reserves the right to change this Privacy Policy
                    from Time to Time. We will notify you about significant
                    changes in the way we treat personal information by sending
                    a notice to the primary email address specified in your
                    account, by placing a prominent notice on our site, and/or
                    by updating any privacy information on this page. Your
                    continued use of the Site and/or Services available through
                    this Site after such modifications will constitute your: (a)
                    acknowledgement of the modified Privacy Policy; and (b)
                    agreement to abide and be bound by that Policy.
                </p>
                Contact
                <a href="mailto:support@breaksrus.com"> support@breaksrus.com</a>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
