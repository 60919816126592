export const Assets = {
    ARROW_DOWN: require('../assets/img/arrow_down.svg'),
    ARROW_UP: require('../assets/img/arrow_up.svg'),
    COLLIMATION_MARK_WHITE: require('../assets/img/collimation_mark_white.svg'),
    COLLIMATION_MARK_BLACK: require('../assets/img/collimation_mark_black.svg'),
    COLLIMATION_MARK: require('../assets/img/collimation_mark.svg'),
    ICON_CLEAN: require('../assets/img/icon_clean.svg'),
    ICON_DIRTY: require('../assets/img/icon_dirty.svg'),
    ICON_CLEAN_ORANGE: require('../assets/img/icon_clean_orange.svg'),
    ICON_DIRTY_ORANGE: require('../assets/img/icon_dirty_orange.svg'),
    ICON_FAST_FORWARD: require('../assets/img/icon_fast_forward.svg'),
    ICON_HOME_ORANGE: require('../assets/img/icon_home_orange.svg'),
    ICON_HOME: require('../assets/img/icon_home.svg'),
    ICON_LIKED_SONGS_ORANGE: require('../assets/img/icon_liked_songs_orange.svg'),
    ICON_LIKED_SONGS_ORANGE_FILLED: require('../assets/img/icon_liked_songs_orange_filled.svg'),
    ICON_LIKED_SONGS_WHITE: require('../assets/img/icon_liked_songs_white.svg'),
    ICON_LIKED_SONGS: require('../assets/img/icon_liked_songs.svg'),
    ICON_LOUP: require('../assets/img/icon_loup.svg'),
    ICON_MOST_LIKED_SONGS_ORANGE: require('../assets/img/icon_most_liked_songs_orange.svg'),
    ICON_MOST_LIKED_SONGS: require('../assets/img/icon_most_liked_songs.svg'),
    ICON_PLAY_WHITE: require('../assets/img/icon_play_white.svg'),
    ICON_PLAY: require('../assets/img/icon_play.svg'),
    ICON_PAUSE: require('../assets/img/icon_pause.svg'),
    ICON_REWIND: require('../assets/img/icon_rewind.svg'),
    ICON_SPEAKER: require('../assets/img/icon_speaker.svg'),
    ICON_SPEAKER_MUTED: require('../assets/img/icon_speaker_muted.svg'),
    ICON_TOP_DOWNLOADS: require('../assets/img/icon_top_downloads.svg'),
    ICON_TOP_DOWNLOADS_ORANGE: require('../assets/img/icon_top_downloads_orange.svg'),
    LOADER: require('../assets/img/loader.svg'),
    LOGO: require('../assets/img/main_logo_white.svg'),
    LOGO_DAY: require('../assets/img/main_logo_black.svg'),
    PLAY_BUTTON: require('../assets/img/play_button.svg'),
    PAUSE_BUTTON: require('../assets/img/pause_button.svg'),
    PLAYING: require('../assets/img/playing.svg'),
    CHEVRON_LEFT: require('../assets/img/chevron-left.svg'),
    EXCLUSIVE_TAG: require('../assets/img/exclusive_tag.svg'),
    HOMEPAGE_ALBUM_1: require('../assets/img/homepage-album-1.jpg'),
    HOMEPAGE_ALBUM_2: require('../assets/img/homepage-album-2.jpg'),
    HOMEPAGE_ALBUM_3: require('../assets/img/homepage-album-3.jpg'),
    HOMEPAGE_ALBUM_4: require('../assets/img/homepage-album-4.jpg'),
    HOMEPAGE_ALBUM_5: require('../assets/img/homepage-album-5.jpg'),
    IMAGE_LIGHT_THEME: require('../assets/img/image_light_theme.png'),
    IMAGE_DARK_THEME: require('../assets/img/image_dark_theme.png'),
}

export const Colors = {
    LIGHT_BLACK: '#151515',
    BLACK: '#0A0A0A',
    ICON_ORANGE: '#F77629',
    ORANGE: '#FF7000',
    WHITE: '#FFFBEB',
    TEXT_WHITE: '#FFF',
    GREY: '#5C5B55',
    LIGHT_GREY: '#605F5A',
    DARK_GREY: '#222222',
    DARK_GREY_2: '#282828',
}